import { createSelector } from "reselect";
import { getRawCluster } from "state/cluster/selectors/details";
import { clusterUpdatesDiffModule } from "state/cluster/actions/updates";
import { ClusterProfileSchema } from "utils/schemas";
import { getEntity } from "utils/entities";

function isUpdateNotificationActionable(notification) {
  const events = notification.action?.events;

  if (!events || notification?.isDone) {
    return;
  }

  return notification;
}

export const getUpdateNotification = createSelector(
  getRawCluster,
  (cluster) => {
    const notifications = cluster?.notifications || [];
    return notifications.find(isUpdateNotificationActionable);
  }
);

export const getIncomingClusterUpdates = createSelector(
  getUpdateNotification,
  (notification) => {
    if (!notification) {
      return [];
    }
    return notification?.events || [];
  }
);

export const getPackDifferences = createSelector(
  (state) => state.cluster.updates.packDifferences,
  (packDifferences) => {
    if (!packDifferences) {
      return {};
    }

    return Object.keys(packDifferences).reduce((accumulator, profile) => {
      const packChanges = packDifferences[profile];

      accumulator[profile] = accumulator[profile] || {};
      Object.keys(packChanges).forEach((pack) => {
        const diffs = packChanges[pack]?.items;
        const current =
          diffs.find((diff) => diff.spec.scope === "spectrocluster")?.spec ||
          null;
        const target =
          diffs.find((diff) => diff.spec.scope === "clusterprofile")?.spec ||
          null;

        accumulator[profile] = accumulator[profile] || {};
        accumulator[profile][pack] = { target, current };
      });

      return accumulator;
    }, {});
  }
);

export const getTargetProfilesEntities = getEntity(
  (state) => state.cluster.updates.targetProfiles,
  [ClusterProfileSchema]
);

export const getUpdatedPacks = createSelector(
  (state) => clusterUpdatesDiffModule.selectors.getAllProfileChanges(state),
  (changes) => {
    return changes.map(({ profile, packs }) => {
      return {
        profile,
        packs: packs
          .filter((packChange) => !!packChange.target)
          .map((packChange) => {
            return {
              ...packChange,
              manifests: packChange.manifests.filter(
                (manifestChange) => !!manifestChange.target
              ),
            };
          }),
      };
    });
  }
);
