export const initialState = {
  devMode: false,
  currentProjectId: null,
  permissions: [],
  allProjects: [],
  projects: [],
  me: null,
  isLoading: false,
  orgLoading: true,
  organization: null,
  step: "email",
  disabledEmail: null,
  plan: null,
  tenantBannerInfo: null,
  systemBannerInfo: null,
  migrationStatus: null,
  orgs: [],
  register: {
    submitted: false,
  },
  appFeatures: null,
};

export function TOGGLE_DEV_MODE(nextState, _, state) {
  nextState.devMode = !state.devMode;
}

export function UPDATE_DEV_MODE(nextState, { devMode }) {
  nextState.devMode = devMode;
}

export function SET_CURRENT_PROJECT(nextState, { currentProjectId }) {
  nextState.currentProjectId = currentProjectId;
}

export function SET_BACK_TO_PROJECT(nextState, { projectUid }) {
  nextState.backToProjectUid = projectUid;
}

export function FETCH_CURRENT_USER_SUCCESS(nextState, { result }) {
  nextState.permissions = result.status.tenantPermissions.tenant;
}

export function FETCH_CURRENT_USER_NORMALIZATION(nextState, { result }) {
  nextState.me = result;
}

export function SET_USER_PROJECTS_NORMALIZATION(nextState, { result }, state) {
  nextState.projects = result;
}

export function FETCH_USER_PROJECTS_NORMALIZATION(
  nextState,
  { result },
  state
) {
  const currentProjects = state.allProjects || [];
  const items = result?.items || [];
  const nextProjects = new Set([...currentProjects, ...items]);
  nextState.allProjects = [...nextProjects];
}

export function APP_LOADING(nextState, { isLoading }) {
  nextState.isLoading = isLoading;
}

export function FETCH_TENANT_ORG_REQUEST(nextState) {
  nextState.orgLoading = true;
}

export function FETCH_TENANT_ORG_SUCCESS(nextState, { result }) {
  nextState.orgLoading = false;
  nextState.currentOrganization = result || {};
}

export function FETCH_TENANT_ORG_FAILURE(nextState, action) {
  nextState.currentOrganization = action.error.details || {};
  nextState.orgLoading = false;
  nextState.orgError = action.error;
}

export function SET_LOGIN_STEP(nextState, { step }) {
  nextState.step = step;
}

export function SET_REDIRECT_URL(nextState, { url }, state) {
  if (state.currentOrganization) {
    nextState.currentOrganization.redirectUrl = url;
  }
}

export function LOGIN_INFO_REQUEST(nextState) {
  nextState.disabledEmail = true;
}

export function LOGIN_INFO_SUCCESS(nextState, { result }) {
  nextState.disabledEmail = false;
  nextState.orgs = result.orgs;
}

export function LOGIN_INFO_FAILURE(nextState) {
  nextState.disabledEmail = false;
}

export function USER_LOGOUT(nextState) {
  nextState.me = null;
}

export function GET_TENANT_PLAN_SUCCESS(nextState, { result }) {
  nextState.plan = result;
}

export function GET_TENANT_BANNER_SUCCESS(nextState, { result }) {
  nextState.tenantBannerInfo = result;
}

export function GET_SYSTEM_BANNER_SUCCESS(nextState, { result }) {
  nextState.systemBannerInfo = result;
}

export function FETCH_QUICKSTART_MIGRATION_STATUS_SUCCESS(
  nextState,
  { result }
) {
  nextState.migrationStatus = result;
}

export function FORM_LOAD_REQUEST(nextState, { module }) {
  if (module === "register") {
    nextState.register.submitted = false;
  }
}

export function FORM_SUBMIT_SUCCESS(nextState, { module }) {
  if (module === "register") {
    nextState.register.submitted = true;
  }
}

export function FETCH_APP_FEATURES_SUCCESS(nextState, { result }) {
  nextState.appFeatures = result;
}
