import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ListingModule from "./ListingModule";
import {
  EDGE_MACHINES_MODULE,
  tagsCache,
} from "state/cluster/services/edgemachines";
import { Blocks, useList } from "modules/list";
import Filters, { InputFieldWrap } from "components/styled/Filters";
import { APPLIANCE_STATES, ARCHITECTURE_TYPES } from "utils/constants";
import dataFetcher from "modules/dataFetcher";
import createAutocompleteComponent from "modules/autocomplete";
import store from "services/store";
import { watchBounds } from "utils/hooks";

const FormFieldsWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-bottom: 8px;

  * + * {
    margin-left: 8px;
  }

  ${InputFieldWrap} {
    margin: 0;
  }
`;

const tagsFetcher = dataFetcher({
  selectors: ["edgehost", "tags"],
  async fetchData(selectors, filters) {
    let tags = tagsCache.selector(store.getState())?.result;
    if (!tags) {
      tags = await store.dispatch(tagsCache.fetch());
    }

    if (filters.search) {
      return Promise.resolve(
        tags.filter((tag) =>
          tag.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase())
        )
      );
    }

    return tags;
  },
});

const TagsFilter = createAutocompleteComponent({
  Component: Blocks.FilterFields.SelectV2,
  dataFetcher: tagsFetcher,
  parseOptions: (options) =>
    options
      .map((tag) => {
        const proccessedTag = tag.includes(":")
          ? tag.split(":")?.join(": ")
          : tag;
        return {
          label: proccessedTag,
          value: proccessedTag,
        };
      })
      .sort((tagA, tagB) => tagA?.label?.localeCompare(tagB?.label)),
});

function EdgeTagFilter(props) {
  const listContext = useList();

  return (
    <TagsFilter
      {...props}
      value={listContext.query.tags}
      onChange={(value) => {
        listContext.changeQuery("tags", value);
      }}
    />
  );
}
export const FilterBounds = watchBounds();

export function EdgeHostFiltersComponent({ top = "-24px", statusOptions }) {
  const { t } = useTranslation();
  const ref = FilterBounds.useRef();
  const labels = {
    tags: t("Tags"),
    states: t("Status"),
    architecture: t("Architecture"),
  };

  return (
    <Filters.FormWrap top={top} ref={ref}>
      <FormFieldsWrap>
        <InputFieldWrap secondary>
          <Blocks.FilterFields.Input
            data-qa="search-filter"
            name="search"
            placeholder={t("Filter by machine id")}
            allowClear
            searchPrefix
          />
        </InputFieldWrap>
        <EdgeTagFilter
          name="tags"
          data-qa="tags"
          label={t("Tags")}
          searchEnabled
          searchPlaceholder={t("Find tags...")}
          mode="multiple"
          searchBy="label"
          module="edge-tags"
        />
        <Blocks.FilterFields.SelectV2
          name="states"
          data-qa="states"
          label={t("Status")}
          options={statusOptions || APPLIANCE_STATES}
          placeholder={t("All")}
          mode="multiple"
        />
        <Blocks.FilterFields.SelectV2
          name="architecture"
          data-qa="architecture"
          label={t("Architecture")}
          options={ARCHITECTURE_TYPES}
          mode="single"
        />
        <Blocks.FiltersToggle visibleKeys={Object.keys(labels)} />
      </FormFieldsWrap>
      <Blocks.FilterSelections
        labels={labels}
        options={{
          states: APPLIANCE_STATES,
          architecture: ARCHITECTURE_TYPES,
        }}
      />
    </Filters.FormWrap>
  );
}

export default function EdgeMachineFilters({ module = EDGE_MACHINES_MODULE }) {
  return (
    <ListingModule module={module} preventInitOnMount>
      <EdgeHostFiltersComponent />
    </ListingModule>
  );
}
