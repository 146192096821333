export const initialState = {
  selectedProfiles: [],
  profilesToValidate: [],
  isConfigurationValid: true,
  errorReason: "",
  selectedToSwap: "",
  selectedPack: {},
  searchTerm: "",
  isValidating: false,
  isLoadingNameValidation: false,
};

export function CLUSTER_PAIR_INIT(nextState) {
  Object.keys(initialState).forEach((key) => {
    nextState[key] = initialState[key];
  });
}

export function UPDATE_SELECTED_PROFILES(nextState, { profileKeys }) {
  nextState.selectedProfiles = profileKeys;
}

export function SUBMIT_SELECTED_PROFILES(nextState, { guids }, state) {
  nextState.profilesToValidate = guids;
}

export function CLEAR_SWAP_SELECTION(nextState) {
  nextState.selectedToSwap = "";
}

export function PROFILES_DRAWER_INIT(nextState, { initialSelection }, state) {
  nextState.selectedProfiles = initialSelection;
  nextState.isConfigurationValid = true;
  nextState.errorReason = "";
  nextState.searchTerm = "";
}

export function PAIR_VALIDATION_ERROR(nextState, { errorReason }) {
  nextState.isConfigurationValid = false;
  nextState.errorReason = errorReason;
}

export function CLEAR_PAIR_VALIDATION_ERROR(nextState) {
  nextState.isConfigurationValid = true;
  nextState.errorReason = "";
}

export function REMOVE_PROFILE(nextState, { guid }, state) {
  const currentProfilesToValidate = [...state.profilesToValidate];
  const index = currentProfilesToValidate.findIndex((id) => id === guid);
  currentProfilesToValidate.splice(index, 1);
  nextState.profilesToValidate = currentProfilesToValidate;
}

export function SELECT_TO_SWAP(nextState, { guid }) {
  nextState.selectedToSwap = guid;
}

export function SET_SELECTED_PACK(nextState, { name, tag, version }) {
  nextState.selectedPack = {
    name,
    tag,
    version,
  };
}

export function SET_SEARCH_TERM(nextState, { searchTerm }) {
  nextState.searchTerm = searchTerm;
}

export function VALIDATE_CLUSTER_PAIR_REQUEST(nextState) {
  nextState.isValidating = true;
}

export function VALIDATE_CLUSTER_PAIR_SUCCESS(nextState) {
  nextState.isValidating = false;
}

export function VALIDATE_CLUSTER_PAIR_FAILURE(nextState) {
  nextState.isValidating = false;
}

export function CLUSTER_PAIR_NAME_VALIDATION_REQUEST(nextState) {
  nextState.isLoadingNameValidation = true;
}

export function CLUSTER_PAIR_NAME_VALIDATION_SUCCESS(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function CLUSTER_PAIR_NAME_VALIDATION_FAILURE(nextState) {
  nextState.isLoadingNameValidation = false;
}
