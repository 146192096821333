import isEqual from "fast-deep-equal";
import debounce from "redux-debounce-thunk";
import entities, { createEntity } from "utils/entities";
import { v4 as uuid } from "uuid";
import { listingTablePreferences } from "services/localstorage/cache";
const DEFAULT_LIMIT_SIZE = 50;

export default class ListActions {
  constructor(actions) {
    if (actions instanceof ListActions) {
      return actions;
    }

    const {
      fetchData,
      schema,
      debounceDelay = 1000,
      initialQuery = () => ({}),
      defaultQuery,
      dataFetcher,
      hasPagination,
      identifier,
      persistFilters = false,
      filtersFetcher,
      fetchFilters,
      filtersConfig = {},
      loadStrategy,
    } = actions;

    Object.assign(this, {
      fetchData,
      schema,
      initialQuery,
      dataFetcher,
      hasPagination,
      identifier,
      defaultQuery,
      persistFilters,
      filtersFetcher,
      fetchFilters,
      filtersConfig,
      loadStrategy,
    });

    this.debouncedFetchItems = debounce(this.fetchItems, debounceDelay);
  }

  initialize(module, initialQuery = this.initialQuery()) {
    return (dispatch) => {
      const cache = listingTablePreferences.get(module) || {};

      const {
        columnPreferences = {},
        query: cachedQuery = {},
        showFilterSelections = true,
        extended = false,
        pinned = [],
      } = cache;

      const newQuery = {
        ...cachedQuery,
        ...initialQuery,
      };

      dispatch({
        type: "LIST_INITIALIZE",
        query: { limit: DEFAULT_LIMIT_SIZE, ...newQuery },
        defaultQuery: this.defaultQuery,
        module,
        columnPreferences,
        showFilterSelections,
        filtersConfig: {
          extended,
          pinned,
          config: {
            ...this.filtersConfig,
          },
        },
      });
      dispatch(this.fetchListingFilters(module));
      return dispatch(this.createFetchAction(module, "LIST_INITIALIZE"));
    };
  }

  fetchListingFilters(module) {
    return (dispatch, getState) => {
      const apiCall = async () => {
        let data = {};
        if (this.filtersFetcher) {
          await dispatch(this.filtersFetcher.fetch());
          data = this.filtersFetcher.selector(getState()).result;
        }

        if (this.fetchFilters) {
          data = this.fetchFilters();
        }

        return data;
      };

      dispatch({
        type: "LOAD_FILTERS",
        module,
        promise: apiCall(),
      });
    };
  }

  addItems({ module, items }) {
    return (dispatch) => {
      const entity = createEntity(items, this.schema);
      const normalization = entities.normalize(entity, this.schema);
      dispatch({
        type: "ADD_ENTITIES",
        ...normalization,
      });
      dispatch({
        type: "LIST_ADD_ITEMS",
        module,
        items: normalization.result,
        hasPagination: this.hasPagination,
      });
    };
  }

  removeItems({ module, items }) {
    return {
      type: "LIST_REMOVE_ITEM",
      module,
      items,
    };
  }

  nextPage(module) {
    return (dispatch, getState) => {
      const listState = getState().list[module];
      if (listState.isLoading || !listState.nextToken) {
        return;
      }
      dispatch({ type: "LIST_NEXT_PAGE", module });
      return dispatch(this.fetchItems(module));
    };
  }

  goToPage({ module, pageNumber }) {
    return (dispatch, getState) => {
      const { count, query } = getState().list[module] || {};
      const lastPageNumber = Math.ceil(count / query.limit);
      if (pageNumber > lastPageNumber) {
        return;
      }
      dispatch({
        type: "LIST_GO_TO_PAGE",
        module,
        currentPageNumber: pageNumber,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  fetchItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_FETCH_ITEMS"));
    };
  };

  refreshItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_REFRESH_ITEMS"));
    };
  };

  createFetchAction(module, type) {
    return async (dispatch, getState) => {
      const { query, currentPageNumber, token } = getState().list[module] || {};
      let offset;
      if (this.hasPagination) {
        offset = (currentPageNumber - 1) * query.limit;
      }

      const promise = async () => {
        if (!this.fetchData && !this.dataFetcher) {
          return {
            items: [],
          };
        }

        let data = {
          items: [],
        };

        const fetchQuery = {
          ...query,
          continue: token !== "initial" ? token : undefined,
          offset,
        };

        if (this.dataFetcher) {
          const dataFetcher = this.identifier
            ? this.dataFetcher.key(this.identifier)
            : this.dataFetcher;

          await dispatch(dataFetcher.fetch(fetchQuery));
          data = dataFetcher.selector(getState()).result;
        }

        if (this.fetchData) {
          data = await this.fetchData(fetchQuery);
        }

        dispatch({
          type: "LIST_SET_ITEMS_META",
          nextToken: data?.listmeta?.continue,
          count: data?.listmeta?.count,
          module,
        });

        return data?.items || [];
      };

      const apiCall = promise();
      const requestId = uuid();
      await dispatch({
        type,
        module,
        promise: apiCall,
        token,
        schema: this.schema,
        currentPageNumber,
        hasPagination: this.hasPagination,
        requestId,
      });

      if (this.loadStrategy === "all") {
        dispatch(this.nextPage(module));
      }
      return apiCall;
    };
  }

  changeQuery({ name, value, module }) {
    return (dispatch) => {
      dispatch({
        module: module,
        type: "LIST_CHANGE_QUERY",
        name,
        value,
        hasPagination: this.hasPagination,
      });
      dispatch(this.saveFilterPreferences(module));
      dispatch(this.debouncedFetchItems(module));
    };
  }

  clearPersistedFilters(module) {
    return () => {
      listingTablePreferences.set(module, {});
    };
  }

  batchChangeQuery({ module, query }) {
    return (dispatch, getState) => {
      if (isEqual(query, getState().list[module]?.query)) return;

      dispatch({
        module,
        type: "BATCH_CHANGE_QUERY",
        query,
        hasPagination: this.hasPagination,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  onPageSizeChange({ module, newLimit }) {
    return (dispatch) => {
      dispatch(
        this.changeQuery({
          module,
          name: "limit",
          value: newLimit,
        })
      );
      dispatch(this.saveFilterPreferences(module));
    };
  }

  saveFilterPreferences(module) {
    return (_, getState) => {
      if (!this.persistFilters) {
        return;
      }
      const query = getState()?.list[module]?.query || {};
      const cache = listingTablePreferences.get(module) || {};

      const newCache = {
        ...cache,
        query,
      };

      listingTablePreferences.set(module, {
        ...newCache,
      });
    };
  }

  toggleFiltersVisibility(module) {
    return (dispatch, getState) => {
      const showFilterSelections =
        getState()?.list[module]?.showFilterSelections;
      dispatch({
        module,
        type: "TOGGLE_SHOW_FILTER_SELECTIONS",
        value: !showFilterSelections,
      });
      dispatch(saveFiltersVisibilityPreferences(module));
    };
  }
}

const saveFiltersVisibilityPreferences = (module) => {
  return (_, getState) => {
    const showFilterSelections = getState()?.list[module]?.showFilterSelections;
    const cache = listingTablePreferences.get(module) || {};

    listingTablePreferences.set(module, {
      ...cache,
      showFilterSelections,
    });
  };
};
