import { denormalize } from "normalizr";
import entities from "utils/entities";

export const api = () => (next) => async (action) => {
  const { type, promise, schema, ...rest } = action;
  const isPromise = promise instanceof Promise;
  if (!isPromise || !type) {
    next(action);
    return;
  }

  next({ type: `${type}_REQUEST`, ...rest });
  try {
    let result = await promise;
    if (schema) {
      const normalization = next({ type, ...rest, data: result, schema });
      result = normalization;
    }
    next({ type: `${type}_SUCCESS`, ...rest, result });
    return result;
  } catch (error) {
    console.error(error);
    next({ type: `${type}_FAILURE`, ...rest, error });
  }
};

export const normalize = (store) => (next) => (action) => {
  const { type, schema, data, ...rest } = action;
  if (!schema || !data) {
    next(action);
    return;
  }

  const normalization = entities.normalize(data, schema);
  next({
    type: "ADD_ENTITIES",
    entities: normalization.entities,
  });

  next({
    type: `${type}_NORMALIZATION`,
    ...rest,
    ...normalization,
  });

  return denormalize(normalization.result, schema, store.getState().entities);
};
