import i18next from "i18next";
import { createSelector } from "reselect";

import { pairingClusterDetailsFetcher } from "./services";
import { CLUSTER_PAIRING_FORM_MODULE } from "./actions";
import { pairingRequestsMetadataFetcher } from "./services";
import { getStoreEntity } from "services/store";
import { PairingRequestSchema, ClusterProfileSchema } from "utils/schemas";
import { formatTags, presentLayer } from "utils/presenters";
import { getEntity } from "utils/entities";

export const getPairingRequestsMetadata = createSelector(
  pairingRequestsMetadataFetcher.selector,
  ({ result }) => {
    return result;
  }
);

export const getPairingRequestsGuids = createSelector(
  (state) => getPairingRequestsMetadata(state),
  (pairingRequestsMetadata) => {
    return (pairingRequestsMetadata || []).map(
      (pairingRequest) => pairingRequest?.guid
    );
  }
);

export const getPairingRequestsDetails = getEntity(getPairingRequestsGuids, [
  PairingRequestSchema,
]);

const getMatchState = (source = [], target = []) => {
  return source.reduce((accum, sourceItem) => {
    const { name, tag, version } = sourceItem;
    const isDuplicatedInSource = source.filter(
      (itm) => itm.name === name && (itm.tag === tag || itm.version === version)
    );

    const matches = target.filter(
      (targetItm) =>
        targetItm.name === name &&
        (targetItm.tag === tag || targetItm.version === version)
    );
    const isDuplicated = matches.length > 1 || isDuplicatedInSource.length > 1;
    accum[`${name} ${tag || version}`] = !target.length
      ? "initial"
      : isDuplicated
      ? "duplicate"
      : matches.length
      ? "matched"
      : "unmatched";
    return accum;
  }, {});
};

const getProfilePacks = (profiles) => {
  return profiles.reduce((accum, { packs = [] }) => {
    accum.push(...packs);
    return accum;
  }, []);
};

const getRawCurrentPacks = createSelector(
  (state) =>
    pairingClusterDetailsFetcher.selector(state)?.result?.spec.profiles,
  (currentProfiles = []) => {
    return getProfilePacks(currentProfiles);
  }
);

export const getFormattedNewProfiles = createSelector(
  (state) => state.clusterPair?.profilesToValidate,
  getRawCurrentPacks,
  (profilesToValidate = [], currentPacks) => {
    const allNewPacks = [];
    const profilesEntities = profilesToValidate.map((guid) => {
      const profile = getStoreEntity(guid, ClusterProfileSchema);
      allNewPacks.push(...profile.specSummary.published?.packs);
      return { ...profile, guid };
    });
    const matchingStates = getMatchState(allNewPacks, currentPacks);
    return profilesEntities.map((profile) => {
      return {
        guid: profile.guid,
        name: profile.metadata.name,
        cloudType: profile.specSummary.published?.cloudType,
        uid: profile.metadata.uid,
        type: profile.specSummary.published.type,
        packs: profile.specSummary.published?.packs?.map((pack) => {
          return {
            ...pack,
            label: `${pack?.name} ${pack?.tag || pack?.version}`,
            value: pack.packUid,
            "data-qa-value": `${pack?.name} ${pack?.tag || pack?.version}`,
            matchState:
              matchingStates[`${pack?.name} ${pack?.tag || pack?.version}`],
            matchStateTooltip: {
              unmatched: i18next.t(
                "Pack is not found in running configuration"
              ),
            },
          };
        }),
      };
    });
  }
);

export const getNewPacks = createSelector(
  getFormattedNewProfiles,
  (profiles = []) => {
    return getProfilePacks(profiles);
  }
);

export const getCurrentProfiles = createSelector(
  (state) =>
    pairingClusterDetailsFetcher.selector(state)?.result?.spec.profiles,
  getNewPacks,
  (currentProfiles = [], newPacks = []) => {
    const allCurrentPacks = [];
    currentProfiles.forEach(({ packs = [] }) => {
      allCurrentPacks.push(...packs);
    });
    const matchingStates = getMatchState(allCurrentPacks, newPacks);
    return currentProfiles.map((profile = {}) => ({
      ...profile,
      packs: profile.packs.map((pack) => {
        return {
          ...pack,
          label: `${pack.name} ${pack?.tag || pack?.version}`,
          value: pack.packUid,
          "data-qa-value": `${pack.name} ${pack?.tag || pack?.version}`,
          matchState:
            matchingStates[`${pack?.name} ${pack?.tag || pack?.version}`],
        };
      }),
    }));
  }
);

export const getCurrentPacks = createSelector(
  getCurrentProfiles,
  (profiles = []) => {
    return getProfilePacks(profiles);
  }
);

export const getNewProfiles = createSelector(
  (state) => state.forms[CLUSTER_PAIRING_FORM_MODULE]?.data?.profiles,
  (profiles = []) => {
    return profiles;
  }
);

export const getLockedProfiles = createSelector(
  (state) => state.clusterPair?.profilesToValidate,
  (state) => state.clusterPair?.selectedToSwap,
  (profiles = [], selectedToSwap = "") => {
    const profilesClone = [...profiles];
    const swapIndex = profilesClone.findIndex(
      (guid) => guid === selectedToSwap
    );
    if (swapIndex > -1) {
      profilesClone.splice(swapIndex, 1);
      return profilesClone;
    }
    return [];
  }
);

export const getSelectedPackDetails = createSelector(
  getCurrentPacks,
  (state) => state.clusterPair?.selectedPack,
  (packs = [], { name = "", tag = "", version = "" }) => {
    const pack = packs.find(
      (pack) =>
        pack.name === name && (pack.tag === tag || pack.version === version)
    );
    return pack || {};
  }
);

export const getNewProfilesFiltered = createSelector(
  getNewProfiles,
  (state) => state.clusterPair?.searchTerm,
  (profiles = [], searchTerm = "") => {
    return profiles.reduce((accum, profile) => {
      const { packs = [] } = profile;
      const filteredPacks = packs.filter((pack) => {
        return (
          pack?.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          pack?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      if (filteredPacks.length) {
        accum.push({ ...profile, packs: filteredPacks });
      }
      return accum;
    }, []);
  }
);

export const getPairingDetails = createSelector(
  pairingClusterDetailsFetcher.selector,
  ({ result }) => {
    return result || {};
  }
);

export const getPairingClusterFormError = createSelector(
  (state) => state.forms?.[CLUSTER_PAIRING_FORM_MODULE],
  (state) => state.language.language,
  (formState) => {
    if (!formState) {
      return null;
    }

    const { errors } = formState;

    if (!errors.length) {
      return null;
    }

    let message = i18next.t(
      "Please resolve the errors on the page in order to continue"
    );

    if (errors.find((err) => err.field === "profiles")) {
      message = i18next.t("A matching profile is required to proceed.");
    }

    return message;
  }
);

export const getPresentedSelectedProfiles = createSelector(
  getNewProfiles,
  (profiles) => {
    const profileTypePriority = ["cluster", "add-on"];
    return (profiles || [])
      .map((profile) => ({
        guid: profile?.guid,
        name: profile?.name,
        type: profile.type,
        layers: (profile?.packs || []).map((pack) => {
          return presentLayer({
            ...pack,
            type:
              pack?.layer === "addon"
                ? pack?.addonType || pack?.type
                : pack?.layer,
          });
        }),
      }))
      .sort(({ type: profileTypeA }, { type: profileTypeB }) => {
        return (
          profileTypePriority.indexOf(profileTypeB) -
          profileTypePriority.indexOf(profileTypeA)
        );
      });
  }
);

export const getFormattedPayload = createSelector(
  (state) => state.forms?.[CLUSTER_PAIRING_FORM_MODULE].data,
  getCurrentPacks,
  (formData, currentPacks) => {
    const { uid, description, name, tags, profiles } = formData;
    const formattedProfiles = profiles.map((profile) => {
      const { uid = "", packs = [] } = profile;
      return {
        uid,
        packValues: packs.map((pack) => {
          const { tag, version, name, type, manifests } = pack;
          const values =
            currentPacks.find(
              (currentPack) =>
                currentPack.name === name &&
                (currentPack.tag === tag || currentPack.version === version)
            )?.values || "";

          return {
            tag,
            version,
            name,
            type,
            manifests,
            values,
          };
        }),
      };
    });
    return {
      metadata: {
        name,
        annotations: {
          description,
        },
        labels: formatTags(tags),
      },
      spec: {
        pairingUid: uid,
        profiles: formattedProfiles,
      },
    };
  }
);
