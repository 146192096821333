import React, { useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import partition from "lodash/partition";
import { Checkbox } from "antd";
import Icon from "components/ui/Icon";
import { faGear, faThumbtack } from "@fortawesome/pro-light-svg-icons";
import { faGripDots } from "@fortawesome/pro-regular-svg-icons";
import { TextButton } from "components/ui/Button";
import { Divider as AntDivider } from "antd";
import { ListContext } from "modules/list";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  SortableContext,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { white } from "utils/constants/colors";
import { Popover } from "antd";

const OrganizerSlotWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  right: 19px;
`;

const PopoverWrapper = styled.div`
  position: sticky;
  z-index: 100;
  ${({ top }) => css`
    top: ${top}px;
  `}
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
  position: relative;
  top: 8px;
`;

const ContentWrapper = styled.div`
  width: 320px;
  background-color: white;
  border-radius: 3px;
`;

const HeaderWrapper = styled.div`
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-weight: 500;
  }

  button {
    padding: 0;
    font-weight: normal;
  }
`;

const SectionWrapper = styled.div`
  padding-bottom: 4px;
`;

const ColumnsWrapper = styled.div`
  margin: 0 4px;

  .ant-divider-horizontal {
    margin: 4px 0;
  }
`;

const SectionTitle = styled.div`
  height: 32px;
  color: #6a7494;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 10px;
`;

const ColumnRow = styled.div`
  border: 1px solid #edeef4;
  height: 32px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :not(:last-child) {
    margin-bottom: 2px;
  }
`;

const PinIcon = styled(Icon)`
  font-size: 14px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  ${({ pinned }) =>
    pinned &&
    css`
      opacity: 1;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const DragIconWrapper = styled.div`
  margin-right: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const EmptyStateMessage = styled.div`
  font-size: 12px;
  color: #9fa9b7;
  background: #f7f8f9;
  padding: 4px;
  border-radius: 4px;
  font-style: italic;
  text-align: center;
`;

const DraggableRow = ({ column, handleColumnStateChange }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.key });
  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
    backgroundColor: white,
  };

  if (!column.title) {
    return null;
  }

  return (
    <ColumnRow style={itemStyle}>
      <DragIconWrapper ref={setNodeRef} {...attributes} {...listeners}>
        <Icon awesome={faGripDots} />
      </DragIconWrapper>
      <RowContainer>
        <Checkbox
          checked={!column.hidden}
          disabled={column.locked}
          onChange={(ev) => {
            ev.stopPropagation();
            handleColumnStateChange({
              prop: "show",
              key: column.key,
              value: ev.target.checked,
            });
          }}
        >
          {column.title}
        </Checkbox>
        <PinIcon
          pinned={column.fixed ? "pinned" : ""}
          awesome={faThumbtack}
          disabled={column.locked}
          onClick={(ev) => {
            ev.stopPropagation();
            handleColumnStateChange({
              prop: "pin",
              key: column.key,
              value: !column.fixed,
            });
          }}
        />
      </RowContainer>
    </ColumnRow>
  );
};

const ColumnsListSection = ({
  title,
  columns = [],
  handleColumnStateChange,
  dndType,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragOver = ({ active, over }) => {
    const toKey = over?.id;
    if (!toKey) {
      return;
    }

    const fromKeyContainer = active?.data?.current?.sortable?.containerId;
    const toKeyContainer = over?.data?.current?.sortable?.containerId;

    if (!toKeyContainer || fromKeyContainer !== toKeyContainer) {
      return;
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }

    const toKey = over?.id;
    const fromKey = active?.id;

    if (toKey !== fromKey) {
      const toIndex = columns.findIndex((col) => col.key === toKey);
      const fromIndex = columns.findIndex((col) => col.key === fromKey);
      const newCols = arrayMove(columns, fromIndex, toIndex);

      handleColumnStateChange({
        prop: "dnd",
        key: dndType,
        value: newCols.map((col) => col.key),
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <SectionWrapper>
        <SectionTitle>{title}</SectionTitle>
        <SortableContext
          strategy={verticalListSortingStrategy}
          items={columns.map((col) => col.key)}
        >
          {columns.map((column) => (
            <DraggableRow
              key={column.key}
              column={column}
              handleColumnStateChange={handleColumnStateChange}
            />
          ))}
        </SortableContext>
      </SectionWrapper>
    </DndContext>
  );
};

const ColumnsOrganizer = ({ columns = [], sticky = {} }) => {
  const columnActions = useContext(ListContext).columnActions || {};
  const listActions = useContext(ListContext) || {};
  const { resetTableSorting } = listActions;
  const {
    toggleShowColumn,
    togglePin,
    dragAndDropColumn,
    resetColumnsPreferences,
  } = columnActions;
  const { t } = useTranslation();
  const { offsetHeader = 0 } = sticky;
  const ref = useRef(null);
  const [fixedColumns, scrollableColumns] = partition(columns, "fixed");
  const scrollableColumnsKeys = scrollableColumns?.map((col) => col?.key);
  const hasFixedColumns = fixedColumns?.some((col) => col.title);
  const hasScrollableColumns = !!scrollableColumns?.length;

  const onResetColumnsPreferences = () => {
    resetColumnsPreferences();
    resetTableSorting();
  };

  const handleColumnStateChange = (args) => {
    const updaterMap = {
      show: toggleShowColumn,
      pin: togglePin,
      dnd: dragAndDropColumn,
    };
    const updater = updaterMap[args?.prop];
    updater && updater({ ...args, scrollableColumnsKeys });
  };

  return (
    <PopoverWrapper ref={ref} top={offsetHeader}>
      <Popover
        trigger="click"
        placement="bottomRight"
        overlayClassName="column-manager-popover"
        getPopupContainer={() => ref.current}
        title={
          <HeaderWrapper>
            <span>{t("Customize columns")}</span>
            <TextButton onClick={onResetColumnsPreferences}>
              {t("Reset to Default")}
            </TextButton>
          </HeaderWrapper>
        }
        onClick={(ev) => ev.stopPropagation()}
        content={
          <ContentWrapper>
            <ColumnsWrapper>
              {hasFixedColumns ? (
                <>
                  <ColumnsListSection
                    title={t("Fixed Columns")}
                    columns={fixedColumns}
                    handleColumnStateChange={handleColumnStateChange}
                    dndType={"pinned"}
                  />
                </>
              ) : (
                <SectionWrapper>
                  <SectionTitle>{t("Fixed Columns")}</SectionTitle>
                  <EmptyStateMessage>
                    {t("No columns are currently fixed")}
                  </EmptyStateMessage>
                </SectionWrapper>
              )}
              {hasScrollableColumns ? <AntDivider /> : null}
              {hasScrollableColumns ? (
                <ColumnsListSection
                  title={t("Scrollable Columns")}
                  columns={scrollableColumns}
                  handleColumnStateChange={handleColumnStateChange}
                  dndType={"scrollable"}
                />
              ) : null}
            </ColumnsWrapper>
          </ContentWrapper>
        }
      >
        <StyledIcon awesome={faGear} />
      </Popover>
    </PopoverWrapper>
  );
};

export default function ColumnsManager(props) {
  return (
    <OrganizerSlotWrapper>
      <ColumnsOrganizer {...props} />
    </OrganizerSlotWrapper>
  );
}
