import cloneDeep from "lodash/cloneDeep";
import { BASE_LAYERS } from "utils/constants/clusterprofile";

export function reversePacks(profiles) {
  return (profiles || []).map((profile) => {
    const profileClone = cloneDeep(profile);
    (profileClone.spec?.published?.packs || []).reverse();
    return profileClone;
  });
}

export const categorizeProfiles = (profiles = [], markForDeletion = []) => {
  let infraProfile = profiles.find(
    (profile) => !["add-on", "system"].includes(profile.type)
  );
  const systemProfile = profiles.find((profile) => profile.type === "system");
  const addonProfiles = profiles
    .filter((profile) => profile.type === "add-on")
    .map((profile) => ({
      ...profile,
      markedForDeletion: markForDeletion.includes(profile.guid),
    }));
  let extraAddon = null;
  if (infraProfile) {
    const layers = infraProfile.spec.published.packs || [];
    const extraLayers = layers.filter(
      (pack) => !BASE_LAYERS.includes(pack.spec?.layer)
    );

    if (extraLayers.length) {
      const baseLayers = layers.filter((pack) =>
        BASE_LAYERS.includes(pack.spec?.layer)
      );
      extraAddon = {
        ...infraProfile,
        linked: true,
        spec: {
          ...infraProfile.spec,
          published: {
            ...infraProfile.spec.published,
            packs: extraLayers,
          },
        },
      };
      infraProfile = {
        ...infraProfile,
        linked: true,
        spec: {
          ...infraProfile.spec,
          published: {
            ...infraProfile.spec.published,
            packs: baseLayers,
          },
        },
      };
    }
  }

  return {
    infra: extraAddon ? { ...infraProfile, linked: true } : infraProfile,
    addons: extraAddon ? [...addonProfiles, extraAddon] : addonProfiles,
    system: systemProfile,
  };
};

function getVariableDefaultValue(variable) {
  if (variable?.format === "boolean") {
    return variable?.defaultValue === "true";
  }
  return variable?.defaultValue || "";
}

export const getProfileVariables = (profiles = []) => {
  return profiles.reduce((accumulator, profile) => {
    const variables = profile?.variables || [];
    variables.forEach((variable) => {
      accumulator.push({
        ...variable,
        profile: {
          name: profile.metadata.name,
          uid: profile.metadata.uid,
          type: profile?.spec?.published?.type,
          version: profile.spec.version,
        },
      });
    });

    return accumulator;
  }, []);
};

export const getVariablesFormPayload = (formData, profiles = []) => {
  const variables = getProfileVariables(profiles);

  return variables.reduce(
    (accumulator, variable) => ({
      ...accumulator,
      [variable.guid]: {
        profileName: variable.profile.name,
        profileUid: variable.profile.uid,
        name: variable.name,
        value:
          formData?.variables?.[variable?.guid]?.value ||
          getVariableDefaultValue(variable),
        guid: variable.guid,
      },
    }),
    {}
  );
};

export const getGroupedVariables = (profiles) => {
  const variables = getProfileVariables(profiles);

  return variables
    .filter((variable) => !variable.hidden)
    .reduce((accumulator, variable) => {
      const profileName = variable.profile.name;
      const existingProfile = accumulator.find(
        ({ profile }) => profile.name === profileName
      );

      if (existingProfile) {
        existingProfile.variables.push(variable);
      } else {
        accumulator.push({
          profile: {
            ...variable.profile,
          },
          variables: [variable],
        });
      }

      return accumulator;
    }, []);
};

export function getProfilesWithErrors(groupedVariables = [], errors = []) {
  const fieldsWithErrors = errors.map((error) => error.field.split(".")?.[1]);

  return groupedVariables
    .filter((variablesGroup) => {
      const variablesGuids = (variablesGroup.variables || []).map(
        (variable) => variable.guid
      );
      return variablesGuids.some((variableGuid) =>
        fieldsWithErrors.includes(variableGuid)
      );
    })
    .map(({ profile }) => profile?.uid);
}
