import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { winterMist } from "utils/constants/colors";
import {
  faCircleExclamation,
  faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";
import * as colors from "utils/constants/colors";
import Icon from "components/ui/Icon";
import Infra from "assets/icons/profile_type_infra.svg?react";
import Full from "assets/icons/profile_type_full.svg?react";
import Addon from "assets/icons/profile_type_addon.svg?react";

const WarningIcon = styled(Icon)`
  color: ${colors.yellow};
  font-size: 14px;
  margin-left: 6px;
`;

const DisabledIcon = styled(Icon)`
  color: ${(props) => {
    if (props.state === "deleted") return colors.red;
    return colors.greenGray;
  }};
  font-size: 14px;
  margin-left: 6px;
`;

export const BASE_LAYERS = ["os", "k8s", "cni", "csi"];

export const ADDON_LAYERS = [
  "load balancer",
  "ingress",
  "logging",
  "monitoring",
  "security",
  "authentication",
  "system app",
  "servicemesh",
  "registry",
  "spectro",
];

const COMMON_STEPS = ["name", "version", "overview"];

export let PACK_STEPS = BASE_LAYERS.reduce((accumulator, layerType) => {
  accumulator[layerType] = COMMON_STEPS;
  return accumulator;
}, {});

ADDON_LAYERS.forEach((layerType) => {
  PACK_STEPS[layerType] = ["type", ...COMMON_STEPS];
});

PACK_STEPS.k8s = ["version", "overview"];
PACK_STEPS.new = ["type", ...COMMON_STEPS];

export const SCOPE_TAGS = {
  system: { label: "System", color: winterMist },
  tenant: { label: "Tenant", color: "rgba(255, 148, 53, 0.2)" },
  project: { label: "Project", color: "rgba(87, 202, 129, 0.2)" },
};

export const PROFILE_TYPE_TAGS = {
  cluster: {
    label: "FULL",
    color: "rgba(13, 20, 64, 0.2)",
    Icon: Full,
  },
  infra: {
    label: "INFRA",
    color: "rgba(175, 38, 250, 0.1)",
    Icon: Infra,
  },
  "add-on": {
    label: "ADDON",
    color: "rgba(95, 213, 249, 0.2)",
    Icon: Addon,
  },
  // TODO: ask for system color and icon
  system: {
    label: "SYSTEM",
    color: "rgba(95, 213, 249, 0.2)",
    Icon: Addon,
  },
  appProfile: {
    label: "APP PROFILE",
    color: "#C9F3E0",
  },
};

export const PROFILE_CREATION_STEPS_SUBTITLES = {
  "basic-info": () => i18n.t("Basic Information"),
  "profile-layers": () => i18n.t("Profile Layers"),
  "cloud-type": () => i18n.t("Cloud Type"),
  review: () => i18n.t("Review"),
};

export const APP_PROFILE_CREATION_STEPS_SUBTITLES = {
  "basic-info": () => i18n.t("Basic Information"),
  "profile-config": () => i18n.t("Application Profile"),
  review: () => i18n.t("Review"),
};

export const LAYER_DEFAULT_PRECEDENCE = 5;
export const LAYERS_PRECEDENCE = {
  os: 1,
  k8s: 2,
  cni: 3,
  csi: 4,
};

export const CLONE_PROFILE_SCOPE_OPTIONS = [
  { label: "project", value: "project" },
  { label: "tenant", value: "tenant" },
];

export const DISABLED_SYSTEM_STATES = ["disabled", "deleted"];

export const SYSTEM_STATE_TOOLTIP_MSG = (systemState) => {
  return {
    disabled: i18n.t("Disabled"),
    deleted: i18n.t("Deleted"),
    deprecated: i18n.t("Deprecated"),
  }[systemState];
};

export const SYSTEM_STATE_ICON = (systemState) => {
  if (DISABLED_SYSTEM_STATES.includes(systemState)) {
    return <DisabledIcon awesome={faCircleMinus} state={systemState} />;
  }
  return <WarningIcon awesome={faCircleExclamation} />;
};
