import styled from "styled-components";

export const FormFields = styled.div`
  padding: 20px 48px;
  overflow-y: auto;
`;

export const FormSection = styled.div`
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #545f7e;
  display: flex;
  align-items: center;

  &:after {
    content: "";
    border-top: 1px solid #dee1ea;
    flex-grow: 1;
    min-width: 0;
    height: 0;
    margin-left: 8px;
  }
`;
