import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { Menu } from "antd";

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import { getEnvironments } from "utils/constants";
import { PROFILE_TYPE_TAGS } from "utils/constants/clusterprofile";
import { darkBlue, darkRed, white, error } from "utils/constants/colors";
import allPermissions, { PermissionService } from "services/permissions";
import { createAsyncActionComponent } from "modules/asyncAction";

import Icon from "components/ui/Icon";
import Tags from "components/common/Tags";
import { ProfileCardTags } from "components/common/ClusterProfileCard";
import LayerStack from "components/common/LayerStack";
import ActionMenu from "components/common/ActionMenu";

import { deleteProfileAsyncAction } from "state/clusterprofile/actions/listing";
import { formatTimestampToGeneralDateFormat } from "utils/date";
import { useSelector } from "react-redux";
import { FipsIcon } from "components/common/Fips/FipsIcons";
import { isProfileValid } from "utils/domain/profiles";

const TooltipMessage = styled.span`
  font-size: 13px;
  line-height: 21px;
  color: ${white};
  opacity: 0.7;
`;

const ErrorIcon = styled(Icon)`
  color: ${darkRed};
  font-size: 15px;
  margin-right: 5px;
  svg {
    margin: 0;
  }
`;

const ClusterProfileTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  color: #555555;
  span {
    color: #999;
  }
`;

const ProfileType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    margin-bottom: 8px;
  }

  > span {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #9fa9b7;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 24px;

  img {
    color: transparent;
    max-height: 100%;
    object-fit: scale-down;
    max-width: 100%;
  }
`;

const DeleteActionItem = createAsyncActionComponent({
  Component: Menu.Item,
  asyncAction: deleteProfileAsyncAction,
  triggerOnClick: false,
});

export function ClusterProfileType({ data, showLabel = true }) {
  const type = data.spec?.published?.type;
  const { label } = PROFILE_TYPE_TAGS[type] || {};

  return (
    <ProfileType>
      <LayerStack data={data} width={24} />
      {showLabel && <span>{label}</span>}
    </ProfileType>
  );
}

export function useClusterProfileColumns({
  withMenu,
  withSort = true,
  onDelete,
  onClone,
  areSystemType,
  isAdmin,
  isSecurityModeFips = false,
} = {}) {
  const { t } = useTranslation();
  const ENVIRONMENTS = useSelector(getEnvironments);

  return [
    {
      title: null,
      key: "status",
      width: "30px",
      render(data) {
        const isInvalid = !isProfileValid(data);
        if (isInvalid) {
          return (
            <Tooltip
              title={
                <TooltipMessage>{t("Invalid cluster profile")}</TooltipMessage>
              }
              color={darkBlue}
              placement="right"
            >
              <ErrorIcon awesome={faExclamationCircle} />
            </Tooltip>
          );
        }
        return null;
      },
    },
    {
      title: t("Type"),
      key: "profileType",
      width: "80px",
      sorter: withSort,
      render(data) {
        return <ClusterProfileType data={data} />;
      },
    },
    !areSystemType && {
      title: t("Env"),
      key: "environment",
      sorter: withSort,
      width: "70px",
      render(data) {
        let cloudType = data.spec?.published?.cloudType;
        //TODO: remove this once TENCENT cloud clusters are enabled
        if (cloudType === "tencent") {
          cloudType = "tke";
        }

        const env = ENVIRONMENTS.find((item) => item.apiKey === cloudType);
        return env?.smallLogoPath ? (
          <Tooltip title={<span>{env.name}</span>} placement="top">
            <ImgWrap>
              <img src={env.smallLogoPath} alt={cloudType} />
            </ImgWrap>
          </Tooltip>
        ) : null;
      },
    },
    {
      title: t("Profile Name"),
      key: "profileName",
      sorter: withSort,
      render(data) {
        return (
          <ClusterProfileTitle>
            <div>{data.metadata?.name || data.name}</div>
          </ClusterProfileTitle>
        );
      },
    },
    isSecurityModeFips && {
      title: t("FIPS Status"),
      key: "fipsStatus",
      dataIndex: ["status"],
      align: "center",
      width: "50px",
      render({ fips: { mode = {} } = {} }) {
        return <FipsIcon fipsStatus={mode} isClusterVariant />;
      },
    },
    {
      title: t("Last Modified"),
      key: "lastModifiedTimestamp",
      sorter: withSort,
      render(data) {
        return (
          <span>
            {formatTimestampToGeneralDateFormat(
              data.metadata.lastModifiedTimestamp
            )}
          </span>
        );
      },
    },
    {
      title: t("Scope"),
      key: "context",
      render(data) {
        const scope = data.metadata?.annotations?.scope;
        return <ProfileCardTags scope={scope} />;
      },
    },
    {
      title: t("Tags"),
      key: "tags",
      render(data) {
        return <Tags tags={data?.metadata?.labels} />;
      },
    },
    withMenu && {
      title: null,
      key: "menu",
      width: "30px",
      render(data) {
        const { guid, metadata } = data;
        const permissions = new PermissionService(
          metadata?.annotations?.permissions?.split(",")
        );
        const profileScope = metadata?.annotations?.scope;
        const isActionDisabled =
          ["system", "tenant"].includes(profileScope) && !isAdmin;
        const actionMenuOptions = [
          allPermissions.has("clusterProfile.create") &&
            !isActionDisabled && {
              key: "clone",
              label: t("Clone"),
              onClick: () => onClone(guid),
            },
          permissions.has("clusterProfile.delete") &&
            !isActionDisabled && {
              key: "delete",
              label: t("Delete"),
              color: error,
              onClick: () => onDelete(guid),
              identifier: guid,
              Component: DeleteActionItem,
            },
        ].filter(Boolean);

        return <ActionMenu data-qa="action-menu" options={actionMenuOptions} />;
      },
    },
  ].filter(Boolean);
}
