import i18next from "i18next";
import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import notifications from "services/notifications";
import { PairingRequestSchema } from "utils/schemas";

export const pairingRequestsMetadataFetcher = dataFetcher({
  selectors: ["pairing", "requests"],
  schema: [PairingRequestSchema],
  async fetchData() {
    try {
      const response = await api.post(
        "v1/dashboard/spectroclusters/pair/metadata",
        {
          filter: {
            state: {
              ignoreCase: true,
              eq: "pending",
            },
          },
        }
      );
      return response?.items || [];
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to fetch pairing requests metadata"
        ),
        description: err?.message,
      });
      return [];
    }
  },
});

export const pairingClusterDetailsFetcher = dataFetcher({
  selectors: ["pairingCluster"],
  fetchData: async (_1, pairingId) => {
    try {
      const clusterDetails = await api.get(
        `v1/spectroclusters/edge-native/pair/${pairingId}`
      );
      return clusterDetails;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to fetch the pairing details"
        ),
        description: err?.message,
      });
    }
  },
});
