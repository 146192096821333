import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import { getAllProjects } from "state/auth/selectors";
import { getEnvironments } from "utils/constants";
import { presentClusterFilters } from "utils/presenters";
import { getCurrentProjectID } from "state/auth/selectors/common";
import { keyedDataFetcher } from "modules/dataFetcher";

const availableClusterFiltersFetcher = dataFetcher({
  selectors: ["availableClusterFilters"],
  fetchData() {
    return api.get("v1/dashboard/spectroclusters/metadata/search/schema");
  },
});

const clusterFiltersOptionsFetcher = dataFetcher({
  selectors: ["clusterFiltersOptionsFetcher"],
  fetchData() {
    return api.get("v1/dashboard/spectroclusters/search/input");
  },
});

export const clustersTagsCache = keyedDataFetcher({
  selectors: ["cluster", "tags", "cache"],
  async fetchData() {
    const response = await api.get("v1/spectroclusters/tags");
    return response.tags || [];
  },
});

export const clustersTagsFetcher = dataFetcher({
  selectors: ["cluster", "tags"],
  async fetchData(selectors, filters) {
    const currentProjectID = getCurrentProjectID(store.getState());

    let tags = clustersTagsCache
      .key(currentProjectID)
      .selector(store.getState())?.result;

    if (!tags) {
      tags = await store.dispatch(
        clustersTagsCache.key(currentProjectID).fetch()
      );
    }

    if (filters?.search) {
      return tags.filter((tag) =>
        tag.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    return tags;
  },
});

export const clusterFiltersFetcher = dataFetcher({
  selectors: ["clusterListingFilters"],
  async fetchData() {
    const state = store.getState();
    const [filtersResult, optionsResult, clusterTagsResult] =
      await Promise.allSettled([
        store.dispatch(availableClusterFiltersFetcher.fetch()),
        store.dispatch(clusterFiltersOptionsFetcher.fetch()),
        store.dispatch(clustersTagsFetcher.fetch()),
      ]);

    const projects = getAllProjects(state);
    const environments = getEnvironments(state);
    const clusterTags = clusterTagsResult.value || [];

    return presentClusterFilters({
      properties: filtersResult?.value?.schema?.properties,
      options: optionsResult?.value?.inputs,
      projects,
      environments,
      clusterTags,
    });
  },
});
