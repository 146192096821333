import i18next from "i18next";

import createFormActions from "modules/form/actions";
import { createProfileDiffModule } from "modules/profileDifferentiator";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import api from "services/api";

import {
  fetchClusterNotifications,
  fetchClusterProfile,
  DOWNLOAD_AND_INSTALL_LATER,
  DOWNLOAD_AND_INSTALL,
} from "state/cluster/actions/details";
import { clusterRepaveFetcher, profileModule } from "state/cluster/services";
import {
  getIncomingClusterUpdates,
  getPackDifferences,
  getTargetProfilesEntities,
  getUpdateNotification,
  getUpdatedPacks,
} from "state/cluster/selectors/updates";
import { getRawCluster } from "state/cluster/selectors/details";

import {
  ClusterProfileSchema,
  ClusterSchema,
  ManifestSchema,
  PackVersionSchema,
} from "utils/schemas";
import { clusterRevisionsFetcher } from "../services/details";
import { pollNodes } from "utils/tasks";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";
import entities from "utils/entities";

const CLUSTER_UPDATES_FORM_MODULE = "cluster-pending-updates";

export const UPDATE_TYPES = [
  "PackValuesUpdate",
  "PackVersionUpdate",
  "PackUpdate",
  "PackManifestUpdate",
  "PackManifestDelete",
  "PackManifestCreate",
  "PackCreate",
];

export function buildTargetProfiles({
  profiles = [],
  incomingUpdates = [],
  packDiffs = {},
}) {
  return (profiles || []).map((profile) => {
    const profileUpdates = incomingUpdates.filter(
      (event) => event.profileUid === profile.metadata.uid
    );

    if (!profileUpdates.length) {
      return profile;
    }

    const mappedPacks = profile?.packs?.map((pack) => {
      const packUpdate = profileUpdates.find(
        (profileUpdate) => profileUpdate?.packName === pack?.name
      );

      if (!packUpdate) {
        return pack;
      }

      if (packUpdate.type === "PackDelete") {
        return null;
      }

      const diffs = packDiffs[profile.metadata.uid][pack.name];

      const changes = {
        ...(diffs.target.version !== diffs.current.version && {
          version: diffs.target.version,
          values: diffs.target.values,
        }),
        ...(diffs.target.values !== diffs.current.values && {
          values: diffs.target.values,
        }),
      };

      const out = {
        ...pack,
        spec: {
          ...pack,
          ...pack.spec,
          ...changes,
        },
        ...changes,
        manifests: diffs.target.manifests || [],
      };
      return out;
    });

    const profileDiffs = packDiffs[profile.metadata.uid] || {};
    const createUpdates = Object.keys(profileDiffs)
      .map((packName) => profileDiffs[packName])
      .filter((diff) => !diff.current);

    const createdPacks = createUpdates.map((diff) => ({
      ...diff.target,
      spec: {
        ...diff.target,
      },
    }));

    const packs = [...mappedPacks, ...createdPacks].filter(Boolean);
    return {
      metadata: profile.metadata,
      spec: {
        published: {
          packs,
        },
      },
    };
  });
}

function getManifestPromise({ uid, packUid, profileUid, isClusterManifest }) {
  let apiUrl = "";
  if (isClusterManifest) {
    const cluster = getStoreEntity(
      store.getState().cluster.details.currentClusterId,
      ClusterSchema
    );
    apiUrl = `v1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${uid}`;
  } else {
    apiUrl = `v1/clusterprofiles/${profileUid}/packs/${packUid}/manifests/${uid}`;
  }
  const promise = api.get(apiUrl).then((manifest) => ({
    uid: manifest.metadata.uid,
    name: manifest.metadata.name,
    content: manifest.spec?.draft?.content || manifest.spec?.published?.content,
  }));
  return store.dispatch({
    type: "FETCH_ATTACHED_MANIFEST",
    promise,
    schema: ManifestSchema,
  });
}

function extractManifests(
  profiles = [],
  { isProfileFromCluster = false } = {}
) {
  return profiles.reduce((accumulator, profile) => {
    const manifests = profile?.spec?.published?.packs?.flatMap((pack) => {
      const packManifests = pack?.manifests || pack?.spec?.manifests;
      return (packManifests || []).map((manifest) => ({
        ...manifest,
        packUid: pack?.packUid || pack?.metadata?.uid,
        profileUid: profile.metadata.uid,
        isClusterManifest: isProfileFromCluster,
      }));
    });
    accumulator.push(...manifests);
    return accumulator;
  }, []);
}

const clusterUpdatesFormAction = createFormActions({
  async init() {
    const cluster = getRawCluster(store.getState());
    const incomingUpdates = getIncomingClusterUpdates(store.getState());

    // get extra info about events
    const listOfPacks = new Set();
    const promises = incomingUpdates.reduce((acc, event) => {
      if (
        UPDATE_TYPES.includes(event.type) &&
        !listOfPacks.has(event.packName)
      ) {
        const promise = store.dispatch({
          promise: api.get(
            `v1/spectroclusters/${cluster.metadata.uid}/packs/${event.packName}/config`
          ),
          type: "FETCH_CLUSTER_PACK_DIFFS",
          packName: event.packName,
          profileUid: event.profileUid,
          schema: {
            items: [
              {
                spec: PackVersionSchema,
              },
            ],
          },
        });
        listOfPacks.add(event.packName);
        acc.push(promise);
      }

      return acc;
    }, []);

    await Promise.allSettled(promises);
    const packDiffs = getPackDifferences(store.getState());

    // build target profiles
    const currentProfiles = profileModule.state?.initialProfiles || [];
    const targetProfiles = buildTargetProfiles({
      profiles: currentProfiles,
      incomingUpdates,
      packDiffs,
    });

    const normalization = entities.normalize(targetProfiles, [
      ClusterProfileSchema,
    ]);

    store.dispatch({
      type: "ADD_ENTITIES",
      ...normalization,
    });
    store.dispatch({
      type: "SET_CLUSTER_UPDATES_TARGET_PROFILES",
      items: normalization.result,
    });

    const targetProfilesEntities = getTargetProfilesEntities(store.getState());

    // get attached manifests values
    const targetManifests = extractManifests(targetProfiles);
    const currentManifests = extractManifests(currentProfiles, {
      isProfileFromCluster: true,
    });
    const manifestPromises = [...currentManifests, ...targetManifests].reduce(
      (accumulator, manifest) => {
        const promise = getManifestPromise(manifest);
        accumulator.push(promise);
        return accumulator;
      },
      []
    );
    await Promise.allSettled(manifestPromises);

    const values = [...targetProfilesEntities, ...currentProfiles].reduce(
      (accumulator, profile) => {
        (profile?.spec?.published?.packs || []).forEach((pack) => {
          accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
            pack.values || pack?.spec?.values
          );

          const manifests = pack?.manifests || pack?.spec?.manifests;
          (manifests || []).forEach((manifest) => {
            accumulator[manifest.guid] = getPackValuesWithoutPresetsComment(
              getStoreEntity(manifest.guid, ManifestSchema)?.content
            );
          });
        });
        return accumulator;
      },
      {}
    );

    return Promise.resolve(values);
  },
});

function wait(duration = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

export const hybridPoolsUpdatesDiffModule = createProfileDiffModule({
  name: "hybrid-pending-updates",
  reviewAdditions: true,
  selectors: {
    getTargetProfiles: (state) => getTargetProfilesEntities(state),
    getCurrentProfiles: () => profileModule.state?.initialProfiles || [],
    getValues: (state) => {
      return state?.forms?.[CLUSTER_UPDATES_FORM_MODULE]?.data || {};
    },
    getEntities: (state) => state.entities || {},
  },
  onReviewComplete:
    ({ updateLater = false } = {}) =>
    async (dispatch) => {
      const state = store.getState();
      const formData = state.forms?.[CLUSTER_UPDATES_FORM_MODULE]?.data || {};
      const updates = getUpdatedPacks(state);
      const cluster = getRawCluster(state);
      const updateNotification = getUpdateNotification(state);

      const payload = updates.map(({ profile, packs }) => ({
        uid: profile.metadata.uid,
        packs: packs.map(({ target, manifests }) => ({
          name: target.pack.name,
          values: formData[target.pack.guid],
          manifests: manifests.map(({ target }) => ({
            name: target.manifest.name,
            content: formData[target.manifest.guid],
          })),
        })),
      }));

      async function onComplete() {
        // NOTE: wait for modal to actually close
        await wait(400);
        dispatch(fetchClusterNotifications());
        dispatch(fetchClusterProfile());
        dispatch(clusterRevisionsFetcher.key(cluster.metadata.uid).fetch());
        pollNodes.start();
      }

      try {
        await api.patch(`v1/${updateNotification.action.link}`, {
          profiles: payload,
          spcApplySettings: {
            actionType: updateLater
              ? DOWNLOAD_AND_INSTALL_LATER
              : DOWNLOAD_AND_INSTALL,
          },
        });
        notifications.success({
          message: i18next.t("Updates applied to the {{clusterName}} cluster", {
            clusterName: cluster.metadata.name,
          }),
          description: i18next.t("Cluster will update shortly"),
        });
        onComplete();
      } catch (err) {
        notifications.open({
          message: i18next.t(
            "Something went wrong when trying to confirm this notification"
          ),
          description: err.message,
        });

        return Promise.reject();
      }
      api.patch(`v1/notifications/${updateNotification.metadata.uid}/ack`);
    },
  onLayerValuesChange: (layer, values) => (dispatch) => {
    dispatch(
      clusterUpdatesFormAction.onChange({
        module: CLUSTER_UPDATES_FORM_MODULE,
        name: layer,
        value: values,
      })
    );
  },
});

export const clusterUpdatesDiffModule = createProfileDiffModule({
  name: "cluster-pending-updates",
  reviewAdditions: true,
  selectors: {
    getTargetProfiles: (state) => getTargetProfilesEntities(state),
    getCurrentProfiles: () => profileModule.state?.initialProfiles || [],
    getValues: (state) => {
      return state?.forms?.[CLUSTER_UPDATES_FORM_MODULE]?.data || {};
    },
    getEntities: (state) => state.entities || {},
  },
  onReviewComplete:
    ({ updateLater = false } = {}) =>
    async (dispatch) => {
      const state = store.getState();
      const formData = state.forms?.[CLUSTER_UPDATES_FORM_MODULE]?.data || {};
      const updates = getUpdatedPacks(state);
      const cluster = getRawCluster(state);
      const updateNotification = getUpdateNotification(state);

      const payload = updates.map(({ profile, packs }) => ({
        uid: profile.metadata.uid,
        packs: packs.map(({ target, manifests }) => ({
          name: target.pack.name,
          values: formData[target.pack.guid],
          manifests: manifests.map(({ target }) => ({
            name: target.manifest.name,
            content: formData[target.manifest.guid],
          })),
        })),
      }));

      async function onComplete() {
        // NOTE: wait for modal to actually close
        await wait(400);
        dispatch(fetchClusterNotifications());
        dispatch(fetchClusterProfile());
        dispatch(clusterRevisionsFetcher.key(cluster.metadata.uid).fetch());
        pollNodes.start();
      }

      try {
        await api.patch(`v1/${updateNotification.action.link}`, {
          profiles: payload,
          spcApplySettings: {
            actionType: updateLater
              ? DOWNLOAD_AND_INSTALL_LATER
              : DOWNLOAD_AND_INSTALL,
          },
        });
        notifications.success({
          message: i18next.t("Updates applied to the {{clusterName}} cluster", {
            clusterName: cluster.metadata.name,
          }),
          description: i18next.t("Cluster will update shortly"),
        });
        onComplete();
      } catch (err) {
        notifications.open({
          message: i18next.t(
            "Something went wrong when trying to confirm this notification"
          ),
          description: err.message,
        });

        return Promise.reject();
      }
      api.patch(`v1/notifications/${updateNotification.metadata.uid}/ack`);
    },
  onLayerValuesChange: (layer, values) => (dispatch) => {
    dispatch(
      clusterUpdatesFormAction.onChange({
        module: CLUSTER_UPDATES_FORM_MODULE,
        name: layer,
        value: values,
      })
    );
  },
});

export function onOpenClusterUpdatesModal() {
  return async function thunk(dispatch, getState) {
    await store.dispatch({ type: "RESET_CLUSTER_PACK_DIFFS" });
    await dispatch(
      clusterUpdatesFormAction.init({ module: CLUSTER_UPDATES_FORM_MODULE })
    );
    const targetProfiles = getTargetProfilesEntities(getState());
    const repavePayload = targetProfiles.map((profile) => ({
      uid: profile.metadata.uid,
      packValues: (profile?.spec?.published?.packs || []).map((pack) => ({
        name: pack.name,
        manifests: pack?.manifests,
        version: pack?.version,
        values: pack?.values,
        type: pack?.type,
        tag: pack?.version || pack?.tag,
      })),
    }));
    await dispatch(clusterRepaveFetcher.fetch({ profiles: repavePayload }));

    dispatch(clusterUpdatesDiffModule.actions.openDiffSummaryModal());
  };
}
