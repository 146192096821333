import i18next from "i18next";
import createFormActions from "modules/form/actions";
import store from "services/store";
import Validator from "services/validator";
import {
  isValidIP,
  isValidIPRange,
  Missing,
  SemanticVersion,
} from "services/validator/rules";
import { getProfileVariables } from "./selectors";

function getVariableDetails({ name, profileName }, profileVariables) {
  return profileVariables.find(
    (profileVariable) =>
      profileVariable.name === name &&
      profileVariable.profile.name === profileName
  );
}

export default function createProfileFormActions(guid) {
  const getState = () => {
    return store.getState().profileStack[guid];
  };

  function isValidVariable() {
    return ({ name, profileName, value }) => {
      const profiles = getState().profiles;
      const profileVariables = getProfileVariables(profiles);
      const variableDetails = getVariableDetails(
        { name, profileName },
        profileVariables
      );

      const {
        hidden = false,
        immutable = false,
        required = false,
        format = "string",
        regex = null,
      } = variableDetails || {};

      if (hidden || immutable) {
        return false;
      }

      if (required) {
        return Missing()(value);
      }

      if (!!regex) {
        return !new RegExp(variableDetails.regex).test(value)
          ? i18next.t(
              `Value does not match the regex pattern "${variableDetails.regex}"`
            )
          : false;
      }

      if (format === "number") {
        return isNaN(value) ? i18next.t("Value is not a number") : false;
      }

      if (format === "version") {
        return SemanticVersion()(value);
      }

      if (["ipv4", "ipv6"].includes(format)) {
        return isValidIP({
          version: format.replace("ipv", ""),
        })(value);
      }

      if (format === "ipv4cidr") {
        return isValidIPRange({
          version: format.replace("ipv", "").replace("cidr", ""),
        })(value);
      }

      return false;
    };
  }

  const validator = new Validator();
  validator.addRule("variables", function* (value, key, data) {
    for (const key of Object.keys(data.variables)) {
      yield {
        field: `variables.${key}.value`,
        result: isValidVariable()(data.variables[key]),
      };
    }
  });

  return createFormActions({
    validator,
    init: () => {
      return Promise.resolve({
        variables: {},
      });
    },
    submit: (data) => {
      return data;
    },
  });
}
