import merge from "lodash/merge";
import omit from "lodash/omit";

export function LIST_FETCH_ITEMS_REQUEST(
  nextState,
  { module, requestId },
  state
) {
  if (!state[module]) {
    return;
  }
  nextState[module].isRequesting = true;
  nextState[module].isLoading = true;
  nextState[module].requestId = requestId;
}

export function LIST_FETCH_ITEMS_NORMALIZATION(
  nextState,
  { module, result, token, currentPageNumber, hasPagination, requestId },
  state
) {
  if (!state[module] || state[module].requestId !== requestId) {
    return;
  }
  const itemKey = hasPagination ? currentPageNumber : token;
  nextState[module].items[itemKey] = result;
  nextState[module].pages.add(itemKey);
}

export function LIST_INITIALIZE_NORMALIZATION(...args) {
  LIST_FETCH_ITEMS_NORMALIZATION(...args);
}

export function LIST_FETCH_ITEMS_SUCCESS(
  nextState,
  { module, requestId },
  state
) {
  if (!state[module] || state[module].requestId !== requestId) {
    return;
  }
  nextState[module].isRequesting = false;
  nextState[module].isLoading = false;
  nextState[module].isInitializing = false;
}

export function LIST_INITIALIZE_REQUEST(...args) {
  LIST_FETCH_ITEMS_REQUEST(...args);
}

export function LIST_INITIALIZE_SUCCESS(...args) {
  LIST_FETCH_ITEMS_SUCCESS(...args);
}

export function LIST_RESET_ITEMS(nextState, { module, hasPagination }) {
  const token = "initial";
  const pageNumber = 1;
  const itemKey = hasPagination ? pageNumber : token;

  nextState[module].token = token;
  nextState[module].nextToken = null;
  nextState[module].items = {
    [itemKey]: [],
  };
  nextState[module].pages = new Set([itemKey]);
  nextState[module].currentPageNumber = pageNumber;
}

export function LIST_CHANGE_QUERY(
  nextState,
  { module, name, value, hasPagination },
  state
) {
  nextState[module].isLoading = true;
  nextState[module].query[name] = value;
  LIST_RESET_ITEMS(nextState, { module, hasPagination }, state);
}

export function BATCH_CHANGE_QUERY(
  nextState,
  { module, query, hasPagination },
  state
) {
  nextState[module].isLoading = true;
  nextState[module].query = query;
  LIST_RESET_ITEMS(nextState, { module, hasPagination }, state);
}

export function LIST_REMOVE_ITEM(nextState, { module, items = [] }, state) {
  if (items.length === 0) {
    return;
  }
  const pages = state[module].items;
  Object.keys(pages).forEach((page) => {
    items.forEach((guid) => {
      const index = pages[page].indexOf(guid);
      nextState[module].items[page].splice(index, 1);
    });
  });
}

export function LIST_ADD_ITEMS(
  nextState,
  { module, items, hasPagination },
  state
) {
  const token = "initial";
  const pageNumber = 1;
  const itemKey = hasPagination ? pageNumber : token;
  nextState[module].items[itemKey].push(...items);
}

export function LIST_SET_ITEMS_META(
  nextState,
  { module, nextToken, count },
  state
) {
  if (!state[module]) {
    return;
  }
  nextState[module].nextToken = nextToken;
  nextState[module].count = count;
}

export function LIST_NEXT_PAGE(nextState, { module }, state) {
  nextState[module].token = state[module].nextToken;
}

export function LIST_GO_TO_PAGE(
  nextState,
  { module, currentPageNumber },
  state
) {
  nextState[module].isLoading = true;
  nextState[module].currentPageNumber = currentPageNumber;
  nextState[module].token = state[module].nextToken;
}

export function LIST_INITIALIZE(
  nextState,
  {
    module,
    query,
    defaultQuery,
    columnPreferences,
    showFilterSelections,
    filtersConfig = {},
  }
) {
  const initialFilters = {
    extended: false,
    pinned: [],
    config: {
      showExtendedFilters: true,
      showFilterSelections: true,
      showPinnedFilters: true,
      allowClearAllPills: true,
    },
  };
  const filters = merge({}, initialFilters, filtersConfig);
  nextState[module] = {
    query,
    pages: new Set(),
    items: {
      initial: [],
    },
    token: "initial",
    nextToken: null,
    count: 0,
    currentPageNumber: 1,
    isLoading: true,
    isInitializing: true,
    defaultQuery,
    showFilterSelections,
    columns: {
      hidden: [],
      pinned: [],
      scrollable: [],
      resized: {},
      ...columnPreferences,
    },
    filters,
  };
}

export function LIST_REFRESH_ITEMS_NORMALIZATION(
  nextState,
  { module, result, token, currentPageNumber, hasPagination },
  state
) {
  if (!state[module]) {
    return;
  }
  const itemKey = hasPagination ? currentPageNumber : token;
  nextState[module].items[itemKey] = result;
}

export function TOGGLE_SHOW_COLUMN(nextState, { module, value }) {
  nextState[module].columns.hidden = value;
}

export function TOGGLE_PIN(nextState, { module, pinned, scrollable }) {
  nextState[module].columns.pinned = pinned;
  nextState[module].columns.scrollable = scrollable;
}

export function DND_COLUMNS(nextState, { module, key, value }) {
  nextState[module].columns[key] = value;
}

export function RESET_COLUMNS(nextState, { module }) {
  nextState[module].columns = {
    hidden: [],
    pinned: [],
    scrollable: [],
    resized: {},
  };
}

export function RESIZE_COLUMN(nextState, { module, key, value }, state) {
  const resized = state[module]?.columns?.resized;
  nextState[module].columns.resized = {
    ...resized,
    [key]: value,
  };
}

export function TOGGLE_SHOW_FILTER_SELECTIONS(nextState, { module, value }) {
  nextState[module].showFilterSelections = value;
}

export function TOGGLE_EXTENDED(nextState, { module }, state) {
  const extended = state[module]?.filters?.extended;
  nextState[module].filters.extended = !extended;
}

export function LOAD_FILTERS_SUCCESS(nextState, { module, result }, state) {
  const filtersData = state[module].filters.config.data || {};
  const newData = merge({}, filtersData, result);

  nextState[module].filters.config.data = newData;
}

export function TOGGLE_FILTER_PIN(nextState, { module, pinned }) {
  nextState[module].filters.pinned = pinned;
}

export function RESET_FILTERS(nextState, { module }) {
  nextState[module].filters.pinned = [];
}

export function RESET_FILTER_QUERY(
  nextState,
  { module, hasPagination },
  state
) {
  const query = state[module]?.query;
  const filterKeys = state[module].filters?.config?.data || {};
  const newQuery = omit(query, Object.keys(filterKeys));
  nextState[module].query = newQuery;
  LIST_RESET_ITEMS(nextState, { module, hasPagination }, state);
}
