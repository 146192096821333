import { listingTablePreferences } from "services/localstorage/cache";

export default class FilterActions {
  toggleExtendedFilters = ({ module }) => {
    return (dispatch) => {
      dispatch({
        module,
        type: "TOGGLE_EXTENDED",
      });
      dispatch(this.savePreferences({ module }));
    };
  };
  togglePin = ({ module, name }) => {
    return (dispatch, getState) => {
      const pinnedFilters = [...getState().list[module].filters?.pinned] || [];
      const pinIndex = pinnedFilters.findIndex((item) => item === name);
      if (pinIndex !== -1) {
        pinnedFilters.splice(pinIndex, 1);
      } else {
        pinnedFilters.push(name);
      }

      dispatch({
        module,
        type: "TOGGLE_FILTER_PIN",
        pinned: pinnedFilters,
      });

      dispatch(this.savePreferences({ module }));
    };
  };
  savePreferences = ({ module, reset = false }) => {
    return (_, getState) => {
      const {
        extended,
        pinned,
        config: { data = {} },
      } = getState()?.list[module]?.filters || {};
      const query = getState()?.list[module]?.query || {};
      const filterQuery = Object.keys(data).reduce((accum, key) => {
        const queryValue = query[key];
        if (queryValue || queryValue?.length) {
          accum[key] = queryValue;
        }
        return accum;
      }, {});
      const cachedPreferences = listingTablePreferences.get(module) || {};
      const { query: cachedQuery } = cachedPreferences;
      const updates = {
        extended,
        pinned,
        query: reset ? {} : Object.assign({}, cachedQuery, filterQuery),
      };
      const newPreferences = { ...cachedPreferences, ...updates };

      listingTablePreferences.set(module, newPreferences);
    };
  };
  resetFilterPreferences = ({ module }) => {
    return (dispatch) => {
      dispatch({ module, type: "RESET_FILTERS" });
      dispatch(this.savePreferences({ module }));
    };
  };
  resetFilterQuery = ({ module }) => {
    return (dispatch) => {
      dispatch({ module, type: "RESET_FILTER_QUERY" });
      dispatch(this.savePreferences({ module, reset: true }));
    };
  };
}
