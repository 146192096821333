import i18n from "i18next";

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import createReducer from "utils/createReducer";

import cluster from "state/cluster/reducers";
import clusterprofile from "state/clusterprofile/reducers";
import * as users from "state/users/reducer";
import project from "state/project/reducers";
import roles from "state/roles/reducers";
import * as auditReducer from "state/audit/reducers";
import cloudaccounts from "state/cloudaccounts/reducers";
import * as authReducer from "state/auth/reducer";
import * as roleManagement from "state/roleManagement/reducer";
import overlord from "state/overlord/reducers";
import * as ssoSaml from "state/sso/saml/reducers";
import * as ssoReducer from "state/sso/reducers";
import * as registry from "state/packregistries/reducers";
import * as productTour from "state/productTour/reducers";
import * as newCluster from "state/newcluster/reducers";
import * as plandetails from "state/plandetails/reducers";
import * as billingdetails from "state/billingdetails/reducers";
import * as auditTrails from "state/audittrails/reducers";
import * as projectOverview from "state/projectoverview/reducers";
import workspace from "state/workspaces/reducers";
import clusterGroup from "state/clustergroups/reducers";
import * as backupLocations from "state/backuplocations/reducers";
import virtualClusters from "state/nestedclusters/reducers";
import devVirtualClusters from "state/devnestedcluster/reducers";
import * as appDeployments from "state/appdeployments/reducers";
import * as clusterPair from "state/clusterPairing/reducer";

import * as forms from "modules/form/reducer";
import * as list from "modules/list/reducer";
import * as wizard from "modules/wizard/reducer";
import * as fetcher from "modules/dataFetcher/reducer";
import * as asyncAction from "modules/asyncAction/reducer";
import * as profileBuilder from "modules/profileBuilder/reducer";
import * as profileStack from "modules/profileStack/reducer";
import * as profileDifferentiator from "modules/profileDifferentiator/reducer";
import * as appModeOverview from "state/appModeOverview/reducers/appModeOverviewReducers";

import * as language from "./language";
import * as entities from "./entities";
import * as modal from "./modal";
import { appEditReducer } from "modules/profileIDE/flows/application-edit/reducer";
import { appCreateReducer } from "modules/profileIDE/flows/application-create/reducer";

const location = {
  initialState: {
    params: {},
  },
  UPDATE_ROUTER_PARAMS(nextState, { params, path, route }) {
    nextState.params = params;
    nextState.path = path;
    nextState.route = route;
  },
};

export default function createReducers(history, externalReducers = {}) {
  return combineReducers({
    cluster,
    clusterprofile,
    cloudaccounts,
    project,
    roles,
    overlord,
    router: connectRouter(history),
    location: createReducer(location),
    entities: createReducer(entities, null, { useImmer: false }),
    forms: createReducer(forms),
    language: createReducer(language, { language: i18n.language }),
    list: createReducer(list),
    wizard: createReducer(wizard),
    modal: createReducer(modal),
    users: createReducer(users),
    auth: createReducer(authReducer),
    audit: createReducer(auditReducer),
    fetcher: createReducer(fetcher),
    roleManagement: createReducer(roleManagement),
    saml: createReducer(ssoSaml),
    sso: createReducer(ssoReducer),
    registry: createReducer(registry),
    asyncAction: createReducer(asyncAction),
    productTour: createReducer(productTour),
    newCluster: createReducer(newCluster),
    plandetails: createReducer(plandetails),
    billingdetails: createReducer(billingdetails),
    profileBuilder: createReducer(profileBuilder),
    profileStack: createReducer(profileStack),
    profileDifferentiator: createReducer(profileDifferentiator),
    auditTrails: createReducer(auditTrails),
    projectOverview: createReducer(projectOverview),
    backupLocations: createReducer(backupLocations),
    appDeployments: createReducer(appDeployments),
    appModeOverview: createReducer(appModeOverview),
    clusterPair: createReducer(clusterPair),
    workspace,
    virtualClusters,
    clusterGroup,
    devVirtualClusters,
    [appCreateReducer.moduleName]: appCreateReducer.reducer,
    [appEditReducer.moduleName]: appEditReducer.reducer,
    ...externalReducers,
  });
}
