import React from "react";
import store from "services/store";
import i18next from "i18next";
import moment from "moment";
import axios from "axios";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

import {
  ClusterSchema,
  CertificateSchema,
  CloudAccountSchema,
  ClusterProfileTemplateSchema,
  EdgeMachineSchema,
  ClusterProfileSchema,
} from "utils/schemas";
import {
  pollClusterStatus,
  pollClusterNotifications,
  pollClusterLogStatus,
  pollLastClusterModification,
} from "utils/tasks";

import api from "services/api";
import ModalService from "services/modal";
import notifications from "services/notifications";
import Validator from "services/validator";
import { includeMastersCache } from "services/localstorage/cache";
import { Missing, ApplyIf } from "services/validator/rules";

import createFormActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import { getCurrentUser } from "state/auth/selectors";
import {
  clusterEditModal,
  importProcedureModal,
} from "state/cluster/actions/edit";

import { fetchClusterCloudConfig } from "./nodes";

import ListActions from "modules/list/actions";

import {
  getCluster,
  getRawCluster,
  getClusterProfileParams,
  isClusterActive,
  isClusterProvisioning,
  isEventConfirmed,
  getClusterProfileTemplate,
  shouldDisableNodesLogsDownload,
  getClusterDivergencies,
  getClusterCloudType,
  useFailSafeMechanism,
  getRawAttachedProfile,
  isImportModeReadOnly,
  getSandboxClusterLifecycleStatus,
  isEnvVsphereEdge,
  isClusterPending,
} from "state/cluster/selectors/details";
import {
  gaugeFetcher,
  clusterCertificatesFetcher,
  profileModule,
  kubectlRedirectUriFetcher,
  clusterRepaveFetcher,
  clusterRepaveStatusFetcher,
} from "state/cluster/services";
import {
  getDefaultPresetsFromValues,
  getPackValuesWithoutPresetsComment,
  parseAppliances,
  getBoolean,
} from "utils/parsers";
import { getEntity } from "utils/entities";
import { DOWNLOAD_LOGS_OPTIONS } from "utils/constants";
import { TextButton } from "components/ui/Button";
import { updatesFormAction } from "state/cluster/services";
import { fetchRealTimeConsumptionData } from "state/cluster/services";
import dataFetcher from "modules/dataFetcher";
import { getCurrentProjectUidFromUrl } from "state/auth/selectors";
import { fetchAllDashboardClusters } from "state/clustergroups/actions/details";
import {
  onRepaveClusterDiffsModal,
  validateUbuntuProPresets,
} from "./create/common";
import { getCurrentContext } from "state/auth/selectors/common";
import {
  clusterRevisionFetcher,
  clusterRevisionsFetcher,
  profileUpdatesDiffModule,
  revisionDiffModule,
} from "../services/details";
import { macrosSuggestionsFetcher } from "state/macros/actions";

export const CERTS_MODULE = "certificates";
export const DELETE_CLUSTER_FORM_MODULE = "deleteCluster";
export const DOWNLOAD_AND_INSTALL = "DownloadAndInstall";
export const DOWNLOAD_AND_INSTALL_LATER = "DownloadAndInstallLater";

const deleteClusterValidator = new Validator();
deleteClusterValidator.addRule(
  ["clusterName"],
  ApplyIf(() => useFailSafeMechanism(store.getState()), Missing())
);
deleteClusterValidator.addRule(
  ["clusterName"],
  ApplyIf(
    () => useFailSafeMechanism(store.getState()),
    function (value) {
      const clusterName = getCluster(store.getState())?.metadata?.name;
      if (clusterName === value) {
        return false;
      }
      return i18next.t("Field must match the cluster name");
    }
  )
);

function onClusterProfileConfigurationSave(data) {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const clusterUid = state.location.params.id;

    try {
      await dispatch(validateEditClusterProfilePacks(clusterUid));
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to save the configuration"
        ),
        description: error?.message,
      });
      return;
    }

    const { packsValidationErrors } = store.getState().cluster.details;

    if (packsValidationErrors.length > 0) {
      notifications.warning({
        message:
          "One of the packs has validation errors. Please fix them in order to finish the configuration",
      });
      return;
    }

    const promise = api.put(`v1/spectroclusters/${clusterUid}/profiles`, {
      profiles: profileModule.payload,
      spcApplySettings: {
        actionType: data.timeToTrigger,
      },
    });

    dispatch({
      type: "CONFIGURE_CLUSTER_PROFILES",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "An error occured while trying to update the profiles."
        ),
        description: error?.message,
      });
      return;
    }
    notifications.withAudit({
      message: i18next.t("Cluster profiles were updated successfully"),
      promise,
    });

    dispatch(fetchClusterProfile({ withConfiguration: true }));
    dispatch(clusterRevisionsFetcher.key(clusterUid).fetch());

    return promise;
  };
}

export const clusterConfigFormActions = createFormActions({
  init() {
    const params = getClusterProfileParams(store.getState()).reduce(
      (packAccumulator, pack) => {
        return {
          ...packAccumulator,
          [pack.guid]: {
            values: getPackValuesWithoutPresetsComment(pack.values),
            presets: getDefaultPresetsFromValues(pack.values),
          },
        };
      },
      {}
    );
    return Promise.resolve(params);
  },
  submit: (data) => store.dispatch(onClusterProfileConfigurationSave(data)),
});

export function onClusterConfigProfile(timeToTrigger) {
  return (dispatch) => {
    dispatch(
      clusterConfigFormActions.onChange({
        module: "clusterConfig",
        name: "timeToTrigger",
        value: timeToTrigger,
      })
    );

    dispatch(clusterConfigFormActions.submit({ module: "clusterConfig" }));
  };
}

export function onSaveProfileConfiguration() {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const profilesMarkedForDeletion = profileModule.state.markForDeletion;
    const allPacks = profileModule.state.profiles
      .filter((profile) => profile.type !== "system")
      .filter((profile) => !profilesMarkedForDeletion.includes(profile.guid))
      .reduce((acc, profile) => [...acc, ...profile.spec.published.packs], [])
      .map((pack) => pack.spec.name);
    const hasDuplicatePacks = new Set(allPacks).size !== allPacks.length;

    if (hasDuplicatePacks) {
      notifications.warn({
        message: i18next.t(
          "Duplicate packs in multiple profiles are forbidden"
        ),
      });
      return;
    }

    const ubuntuPack = profileModule?.payload[0]?.packValues?.find((pack) =>
      pack.name.includes("ubuntu")
    );
    const validateRequiredPresets = validateUbuntuProPresets(ubuntuPack, state);
    if (!validateRequiredPresets) {
      notifications.error({
        message: i18next.t(
          "Ubuntu Pro, Token and fips presets are required for FIPS compatibility. Change this in the presets section under the Ubuntu pack."
        ),
      });
      return;
    }

    const profilesDiffs =
      profileUpdatesDiffModule.selectors.getFilteredProfileChanges(state);
    const hasUnknownChanges =
      profileUpdatesDiffModule.selectors.hasUnknownManifestChanges(state);

    const hasProfileChanges = !isEmpty(profilesDiffs) && !hasUnknownChanges;
    if (hasProfileChanges) {
      return dispatch(onReviewClusterProfileChanges());
    }

    const isRepaveRequired = await dispatch(
      onValidateClusterRepave(profileModule.payload)
    );
    if (isRepaveRequired) {
      return onRepaveClusterDiffsModal.open().then(() => {
        return dispatch(
          clusterConfigFormActions.submit({ module: "clusterConfig" })
        );
      });
    }

    return dispatch(
      clusterConfigFormActions.submit({ module: "clusterConfig" })
    );
  };
}

function onValidateClusterRepave(profiles) {
  return async (dispatch, getState) => {
    await dispatch(clusterRepaveFetcher.fetch({ profiles }));

    const { result } = clusterRepaveFetcher.selector(getState());
    return result.isRepaveRequired || false;
  };
}

export function onReviewRepave(cluster) {
  return async (dispatch, getState) => {
    const projUid = getCurrentProjectUidFromUrl(getState());
    const { specSummary = {}, metadata = {} } = cluster;
    const { uid = "" } = metadata;
    const projectUid = specSummary?.projectMeta?.uid || projUid;
    await dispatch(clusterRepaveStatusFetcher.fetch({ uid, projectUid }));

    return clusterRepaveStatusFetcher.selector(getState()).result;
  };
}

export function approveClusterRepave(cluster) {
  return async (dispatch, getState) => {
    const projUid = getCurrentProjectUidFromUrl(getState());
    const { specSummary = {}, metadata = {} } = cluster;
    const { uid = "" } = metadata;
    const projectUid = specSummary?.projectMeta?.uid || projUid;
    const promise = api.patch(
      `v1/spectroclusters/${uid}/repave/approve`,
      null,
      {
        headers: { ProjectUid: projectUid },
      }
    );
    dispatch({
      type: "APPROVE_REPAVE",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t("An error occured while approving the repave"),
        description: error?.message,
      });
    }
  };
}

function validateEditClusterProfilePacks(clusterUid) {
  return function thunk(dispatch) {
    const promise = api
      .post(`v1/spectroclusters/${clusterUid}/validate/packs`, {
        profiles: profileModule.payload,
      })
      .then((res) => {
        const packsErrors = res.profiles.map((profile) => ({
          results: profile?.packs?.results,
          uid: profile.uid,
        }));
        profileModule.actions.setLayersErrors(packsErrors);
        return packsErrors;
      });

    dispatch({
      type: "VALIDATE_EDIT_CLUSTER_PROFILE_PACKS",
      promise,
    });

    return promise;
  };
}

function fetchCluster(uid) {
  return function thunk(dispatch, getState) {
    const { clusterCategory } = getState().location.params;
    const context = getCurrentContext(store.getState());

    if (clusterCategory === "clustergroups" || context?.isAdmin) {
      const dashboardClusters = fetchAllDashboardClusters.selector(getState())
        ?.result?.items;

      const projectUid = (dashboardClusters || []).find(
        (cluster) => cluster.metadata.uid === uid
      )?.specSummary?.projectMeta?.uid;
      if (projectUid) {
        const promise = api.get(`v1/spectroclusters/${uid}`, null, {
          headers: { ProjectUid: projectUid },
        });

        dispatch({
          promise,
          type: "FETCH_CLUSTER",
          schema: ClusterSchema,
        });
        return promise;
      }
    }

    const promise = api.get(`v1/spectroclusters/${uid}`);

    dispatch({
      promise,
      type: "FETCH_CLUSTER",
      schema: ClusterSchema,
    });

    return promise;
  };
}

export function refreshCluster() {
  return function thunk(dispatch, getState) {
    const cluster = getRawCluster(getState());
    const promise = api
      .get(`v1/spectroclusters/${cluster?.metadata?.uid}`)
      .then((cluster) => ({
        ...cluster,
        spec: omit(cluster.spec, ["cloudConfigRef"]),
        status: omit(cluster.status, ["addOnServices"]),
      }));
    dispatch({
      promise,
      type: "REFRESH_CLUSTER",
      schema: ClusterSchema,
    });
    return promise;
  };
}

export function refreshClusterConfig() {
  return function thunk(dispatch) {
    dispatch(fetchClusterCloudConfig());
  };
}

export function refreshClusterSpecArch(uid) {
  return function thunk(dispatch, getState) {
    const cluster = getRawCluster(getState());
    const promise = api
      .get(`v1/dashboard/spectroclusters/${uid}`)
      .then((data) => ({
        ...cluster,
        spec: { archTypes: data.spec?.archTypes || [] },
      }));
    dispatch({
      promise,
      type: "REFRESH_CLUSTER",
      schema: ClusterSchema,
    });
    return promise;
  };
}

export function fetchClusterStatus(uid) {
  return async function thunk(dispatch) {
    const promise = api
      .get(`v1/dashboard/spectroclusters/${uid}`)
      .then((data) => ({
        metadata: { ...data.metadata, labels: data.metadata?.labels || null },
        status: { ...data.status },
      }));

    const response = await promise;

    if (
      response?.status?.clusterImport?.state === "Imported" &&
      importProcedureModal?.isOpened()
    ) {
      importProcedureModal.close();
    }

    dispatch({
      promise,
      type: "FETCH_CLUSTER_STATUS",
      schema: ClusterSchema,
    });

    return promise;
  };
}

export function fetchClusterNotifications() {
  return async (dispatch, getState) => {
    const state = getState();
    const cluster = getCluster(state);
    const isActive =
      isClusterActive(state) ||
      isClusterProvisioning(state) ||
      isClusterPending(state);

    if (!isActive) {
      return;
    }

    await dispatch({
      type: "FETCH_CLUSTER_NOTIFICATIONS",
      promise: api
        .get(
          `v1/notifications/spectrocluster/${cluster.metadata.uid}?isDone=false`
        )
        .then((res) => {
          return {
            metadata: cluster.metadata,
            notifications: res.items,
          };
        }),
      schema: ClusterSchema,
    });

    const isAcknowledged = isEventConfirmed(state);
    if (isAcknowledged) {
      pollClusterNotifications.stop();
      return;
    }
  };
}

export function fetchRepaveStatus(uid) {
  return async (dispatch, getState) => {
    const projectUid = getCurrentProjectUidFromUrl(getState());
    dispatch(clusterRepaveStatusFetcher.fetch({ uid, projectUid }));
  };
}

function fetchResolvedValues(uid) {
  return function (dispatch) {
    const promise = api
      .get(`v1/spectroclusters/${uid}/packs/resolvedValues`)
      .then((res) => res.profiles)
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        notifications.error({
          message: i18next.t(
            "An error occured while trying to get the resolved values."
          ),
          description: error?.message,
        });
      });

    dispatch({
      type: "FETCH_RESOLVED_VALUES",
      promise,
    });

    return promise;
  };
}

export function fetchClusterProfile({ withConfiguration = false } = {}) {
  return async function (dispatch, getState) {
    dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });
    profileModule.actions.initialize({ profiles: [] });

    let cluster = getRawCluster(getState());
    const getClusterPromise = api
      .get(`v1/spectroclusters/${cluster.metadata.uid}`)
      .then((data) => ({
        metadata: {
          uid: data.metadata.uid,
        },
        spec: {
          clusterProfileRef: data.spec.clusterProfileRef,
          clusterProfileTemplates: data.spec.clusterProfileTemplates,
        },
      }));
    dispatch({
      promise: getClusterPromise,
      type: "REFRESH_CLUSTER_PACKS",
      schema: ClusterSchema,
    });

    await getClusterPromise;
    cluster = getRawCluster(getState());

    if (withConfiguration) {
      const resolvedValues = await dispatch(
        fetchResolvedValues(cluster.metadata.uid)
      );
      profileModule.actions.updateResolvedValues(resolvedValues);
      store.dispatch(macrosSuggestionsFetcher.fetch());
    }

    const profilesPromise = api
      .get(
        `v1/spectroclusters/${cluster.metadata.uid}/profiles?includePackMeta=schema,presets`
      )
      .then((response) => {
        return response.profiles.map((profile) => ({
          ...profile,
          packs: profile.spec.packs.map((pack) => ({
            ...pack.spec,
            ...pack.metadata,
            spec: pack.spec,
            packUid: pack.metadata.uid,
          })),
        }));
      });

    dispatch({
      promise: profilesPromise,
      type: "FETCH_CLUSTER_BUNDLE",
      schema: [ClusterProfileTemplateSchema],
    });

    await profilesPromise;
    let profiles = getClusterProfileTemplate(getState());
    function fetchProfile(uid) {
      const promise = api.get(`v1/clusterprofiles/${uid}`);
      store.dispatch({
        type: "PROFILE_STACK.FETCH_PROFILE",
        promise,
        schema: ClusterProfileSchema,
      });
      return promise;
    }

    if (withConfiguration) {
      await Promise.allSettled(
        profiles.map(async (profile) => {
          return fetchProfile(profile.metadata.uid);
        })
      );
      profiles = getClusterProfileTemplate(getState());
    }

    const clusterLabels = cluster?.metadata?.labels;
    const cloudType = cluster.spec.cloudType;
    const ignoreImport =
      clusterLabels?.imported === "false" || cloudType === "edge-native";

    profileModule.actions.initialize({
      profiles,
      options: {
        cloudType: cluster.spec.cloudType,
        isVsphereEdge: isEnvVsphereEdge(getState()),
        editMode: true,
        allowInfraProfileRemoval: false,
        allowSystemProfiles: ignoreImport
          ? false
          : cluster.metadata.annotations.primaryEdgeHostUid,
      },
    });

    dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE_SUCCESS" });

    const redirectSelectedPack = getState().location.params.layerUid;
    if (redirectSelectedPack) {
      profiles.forEach((profile) => {
        const foundPack = profile.spec?.published?.packs.find(
          (pack) => pack?.metadata.uid === redirectSelectedPack
        );

        if (!foundPack) {
          return;
        }

        if (foundPack.spec.type === "manifest") {
          profileModule.actions.onManifestSelect({
            manifestGuid: foundPack.manifests?.[0].guid,
            layerGuid: foundPack.guid,
            profileGuid: profile.guid,
            editMode: true,
            isAttachedManifest: false,
          });
          return;
        }

        profileModule.actions.selectLayer(foundPack.guid);

        return;
      });
    }
  };
}

export function attachClusterProfile(profileUid) {
  return async function (dispatch, getState) {
    try {
      await dispatch(fetchAttachedProfileByUid(profileUid));
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      notifications.error({
        message: i18next.t(
          "The profile that you want to attach was not found."
        ),
        description: error?.message,
      });
    }

    const attachedProfile = getRawAttachedProfile(getState());
    profileModule.actions.selectProfile(attachedProfile.guid);
    profileModule.actions.attachProfile();
  };
}

export function getClusterByUid(uid) {
  return async (dispatch, getState) => {
    let cluster = getCluster(getState());

    if (cluster?.metadata?.uid === uid) {
      pollClusterNotifications.start();
      pollClusterStatus.start();
      pollLastClusterModification.start();
      return;
    }

    await dispatch(fetchCluster(uid));
    cluster = getCluster(getState());
    await dispatch(fetchClusterCloudConfig());

    cluster = getCluster(getState());

    if (
      cluster.spec.cloudType === "vsphere" &&
      cluster.spec.cloudConfig?.spec?.cloudAccountRef?.uid
    ) {
      dispatch({
        type: "FETCH_CLOUD_ACCOUNT",
        promise: api.get(
          `v1/cloudaccounts/vsphere/${cluster.spec.cloudConfig.spec.cloudAccountRef.uid}`
        ),
        schema: CloudAccountSchema,
      });
    }

    pollClusterStatus.start();
    pollClusterNotifications.start();
    pollLastClusterModification.start();
  };
}

export const clusterUpdateConfirm = new ModalService("clusterUpdate");

export const deleteClusterModal = new ModalService("deleteCluster");
export const deleteClusterAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      deleteClusterFormActions.submit({ module: DELETE_CLUSTER_FORM_MODULE })
    );
  },
});

function deleteCluster() {
  return async (dispatch) => {
    const cluster = getRawCluster(store.getState());
    const clusterName = cluster.metadata.name;
    const forceDelete = deleteClusterModal?.data?.forceDelete;

    const promise = api.delete(
      `v1/spectroclusters/${cluster.metadata.uid}${
        forceDelete ? "?forceDelete=true" : ""
      }`
    );

    store.dispatch({
      type: "DELETE_CLUSTER",
      promise,
    });
    deleteClusterModal.close();

    try {
      await promise;
      pollClusterStatus.start();
      const notificationMessage = forceDelete
        ? i18next.t("Force deletion of '{{clusterName}}' is in progress", {
            clusterName,
          })
        : i18next.t("Deletion of cluster '{{clusterName}}' is in progress", {
            clusterName,
          });

      notifications.open({
        message: notificationMessage,
      });
      clusterEditModal.close();
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while deleting {{clusterName}}",
          { clusterName }
        ),
        description: error?.message,
      });
    }

    return promise;
  };
}

export const deleteClusterFormActions = createFormActions({
  init() {
    return Promise.resolve({ clusterName: "" });
  },
  validator: deleteClusterValidator,
  submit: () => {
    return store.dispatch(deleteCluster());
  },
});

export function onDeleteCluster({ forceDelete = false } = {}) {
  return function thunk(dispatch) {
    dispatch(
      deleteClusterFormActions.init({
        module: DELETE_CLUSTER_FORM_MODULE,
      })
    );
    deleteClusterModal.open({ forceDelete }).then(() => {
      const isReadOnlyImport = isImportModeReadOnly(store.getState());
      if (isReadOnlyImport) {
        return dispatch(deleteCluster());
      }
      return deleteClusterAsyncAction.trigger();
    });
  };
}

export function togglePoolMetrics() {
  return function thunk(dispatch, getState) {
    dispatch({ type: "TOGGLE_GAUGE_METRICS" });

    const state = getState();
    const cluster = getRawCluster(state);
    const currentUser = getCurrentUser(state);
    const includeMasters = getBoolean(state?.cluster?.details?.includeMasters);

    includeMastersCache.set(currentUser.metadata.uid, includeMasters);

    dispatch(getGaugeMetrics(cluster?.metadata?.uid));
    dispatch(fetchRealTimeConsumptionData(cluster?.metadata?.uid));
  };
}

export function getGaugeMetrics(clusterUid) {
  return function thunk(dispatch, getState) {
    const state = getState();
    const includeControlPlaneMachines = getBoolean(
      state?.cluster?.details?.includeMasters
    );
    const gaugeQuery = {
      discrete: true,
      startTime: moment().subtract(10, "minutes").utc().format(),
      endTime: moment().utc().format(),
    };

    dispatch(
      gaugeFetcher.fetch(
        clusterUid,
        {
          ...gaugeQuery,
          metricKind: "cpuRequest,cpuTotal,memoryRequest,memoryTotal",
        },
        includeControlPlaneMachines
      )
    );
  };
}

export const renewCertificatesService = new ModalService("renewCertificates");

export const certsListActions = new ListActions({
  dataFetcher: clusterCertificatesFetcher,
  schema: [CertificateSchema],
});

export function onRenewAllCerts() {
  return function thunk(dispatch, getState) {
    renewCertificatesService.open().then(async () => {
      const cluster = getCluster(getState());
      dispatch({ type: "RENEW_ALL_CERTIFICATES" });

      try {
        await api.patch(
          `v1/spectroclusters/${cluster.metadata.uid}/k8certificates/renew`
        );
      } catch (err) {
        notifications.open({
          message: i18next.t(
            "Something went wrong while trying to renew the certificates"
          ),
          description: err.message,
        });

        return;
      }

      notifications.open({
        message: i18next.t("Certificates renewal will begin shortly"),
      });

      dispatch(certsListActions.initialize(CERTS_MODULE));
    });
  };
}

export const LOGS_MODULE = "downloadLogs";
export const downloadLogsModal = new ModalService("downloadLogs");
export const onDemandUpdateModal = new ModalService("onDemandUpdate");
export const pauseResumeSandboxClusterConfirmationModal = new ModalService(
  "pauseResumeSandboxClusterConfirm"
);

const logsValidator = new Validator();
logsValidator.addRule(["logs"], Missing());

export const downloadLogsFormActions = createFormActions({
  init() {
    const shouldDisableNodes = shouldDisableNodesLogsDownload(store.getState());
    const logs = DOWNLOAD_LOGS_OPTIONS.map((log) => log.value);

    return Promise.resolve({
      logs: shouldDisableNodes ? logs.filter((log) => log !== "nodes") : logs,
    });
  },
  validator: logsValidator,
  async submit(data) {
    const cluster = getCluster(store.getState());
    const clusterUid = cluster?.metadata?.uid;
    const kubeSystemLogs = data.logs.includes("kubeSystem");
    const spectroCloudLogs = data.logs.includes("spectroCloud");
    const nodesLogs = data.logs.includes("nodes");

    let clusterNamespace = `cluster-${cluster.metadata.uid}`;
    if (cluster.metadata.annotations?.namespace) {
      clusterNamespace = cluster.metadata.annotations?.namespace;
    }

    const payload = {
      noOfLines: 10000,
      duration: 36000,
      k8s: {
        namespaces: [
          kubeSystemLogs && "kube-system",
          spectroCloudLogs && clusterNamespace,
        ].filter(Boolean),
      },
      node: {
        logs: nodesLogs ? ["/var/log/syslog", "/var/log/cloud-init"] : [],
      },
      mode: "cluster",
    };

    const promise = api.post(
      `v1/spectroclusters/${clusterUid}/features/logFetcher`,
      payload
    );
    store.dispatch({
      type: "LOGS_DOWNLOAD",
      promise,
      clusterGuid: cluster.guid,
    });

    try {
      await promise;
      pollClusterLogStatus.start();
      notifications.success({
        message: i18next.t(
          "The request was sent successfully. The download will be available soon."
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t("An error occurred while preparing the archive"),
        description: error?.message,
      });
    }

    return promise;
  },
});

export const downloadLogsAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      downloadLogsFormActions.submit({ module: LOGS_MODULE })
    );
  },
});

export const onDemandUpdateAsyncAction = new AsyncAction({
  promise: async () => {
    const cluster = getCluster(store.getState());
    const osPatchConfig =
      cluster.spec?.clusterConfig?.machineManagementConfig?.osPatchConfig || {};

    const promise = api.patch(
      `v1/spectroclusters/${cluster.metadata.uid}/clusterConfig/osPatch`,
      {
        osPatchConfig: {
          onDemandPatchAfter: moment().add(10, "minutes"),
          patchOnBoot: osPatchConfig.patchOnBoot,
          rebootIfRequired: osPatchConfig.rebootIfRequired,
        },
      }
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "Node group upgrade activated successfully. If a newer OS version is found, upgrade will begin shortly. "
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while trying to set the update."
        ),
        description: error?.message,
      });
      return;
    }

    store.dispatch(fetchCluster(cluster.metadata.uid));
  },
});

export function openDownloadLogsModal() {
  return (dispatch) => {
    downloadLogsModal.open().then(downloadLogsAsyncAction.trigger);
    dispatch(downloadLogsFormActions.init({ module: LOGS_MODULE }));
  };
}

export function onDemandUpdate() {
  onDemandUpdateModal.open().then(() => {
    onDemandUpdateAsyncAction.trigger();
  });
}

export const pauseResumeSandboxClusterAsyncAction = new AsyncAction({
  promise: async () => {
    const cluster = getCluster(store.getState());
    const clusterLifecycleStatus = getSandboxClusterLifecycleStatus(
      store.getState()
    );

    const pause =
      !clusterLifecycleStatus || clusterLifecycleStatus !== "paused";

    try {
      await api.patch(
        `v1/spectroclusters/${cluster.metadata.uid}/clusterConfig/lifecycleConfig`,
        {
          lifecycleConfig: {
            pause,
          },
        }
      );
      notifications.success({
        message: i18next.t("The virtual cluster will be {{action}} shortly", {
          action: ["paused", "pausing"].includes(clusterLifecycleStatus)
            ? "running"
            : "paused",
        }),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });

      return;
    }

    store.dispatch(fetchCluster(cluster.metadata.uid));
  },
});

export function confirmPauseResumeSandboxClusterModal() {
  return (getState) => {
    pauseResumeSandboxClusterConfirmationModal.open().then(async () => {
      await pauseResumeSandboxClusterAsyncAction.trigger();
    });
  };
}

export function fetchLogStatus() {
  return async (dispatch, getState) => {
    const state = getState();
    const logClusterGuid = state.cluster.details.logClusterGuid;
    const logFetcherUid = state.cluster.details.logFetcherUid;
    const cluster = getEntity(() => logClusterGuid, ClusterSchema)(state);
    const clusterName = cluster.metadata.name;
    const fileName = `${clusterName}-logs-${Date.now()}`;
    const promise = api.get(
      `v1/spectroclusters/${cluster?.metadata?.uid}/features/logFetcher?fileName=${fileName}`
    );
    let response;

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while trying to get the log status"
        ),
        description: error?.message,
      });
      return Promise.resolve();
    }

    if (response?.status?.state === "Completed") {
      const downloadLink = `/v1/spectroclusters/features/logFetcher/${logFetcherUid}/download`;

      notifications.success({
        duration: 0,
        message: i18next.t(
          "The logs archive for {{clusterName}} was created successfully",
          {
            clusterName,
          }
        ),
        description: (
          <TextButton
            data-qa="spectro-logs"
            style={{
              padding: "0",
            }}
            as="a"
            href={downloadLink}
            download={fileName}
          >
            {i18next.t('Download "{{clusterName}}" logs', { clusterName })}
          </TextButton>
        ),
      });
      return Promise.resolve();
    }
    return Promise.reject();
  };
}

export function notificationSelectManifest(profile, manifest, layer) {
  return async function thunk(dispatch, getState) {
    if (manifest.event.type === "PackManifestDelete") {
      return;
    }

    dispatch({
      type: "NOTIFICATION_SELECT_MANIFEST",
      layer,
      manifest,
    });

    const cluster = getRawCluster(getState());

    const manifestGuid = manifest.guid;
    if (
      getState().forms["cluster-update-notification"].data[manifestGuid] !==
      undefined
    ) {
      return;
    }

    let clusterManifestFetcher = () =>
      api.get(
        `v1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${manifest.uid}`
      );
    let profileManifestFetcher = () =>
      manifest.parentUid &&
      api.get(
        `v1/clusterprofiles/${profile.metadata.uid}/packs/${layer.name}/manifests/${manifest.parentUid}`
      );
    if (manifest.event.type === "PackManifestCreate") {
      clusterManifestFetcher = () =>
        api.get(
          `v1/clusterprofiles/${profile.metadata.uid}/packs/${layer.name}/manifests/${manifest.uid}`
        );
    }

    const isInversed = getClusterDivergencies(getState())[manifest.guid]
      .isInversed;

    let [clusterManifest, profileManifest] = [
      await clusterManifestFetcher(),
      await profileManifestFetcher(),
    ];

    if (manifest.event.type === "PackManifestCreate") {
      dispatch(
        updatesFormAction.batchChange({
          module: "cluster-update-notification",
          updates: {
            [manifestGuid]: clusterManifest?.spec?.published?.content,
          },
        })
      );

      return;
    }

    dispatch(
      updatesFormAction.batchChange({
        module: "cluster-update-notification",
        updates: {
          [manifestGuid]: !isInversed
            ? profileManifest?.spec?.published?.content
            : clusterManifest?.spec?.published?.content,
        },
      })
    );

    if (profileManifest) {
      dispatch({
        type: "UPDATE_CLUSTER_MANIFEST_DIVERGENCE",
        profile,
        manifest,
        layer,
        content: !isInversed
          ? clusterManifest?.spec?.published?.content
          : profileManifest?.spec?.published?.content,
      });
    }
  };
}

export function notificationSelectLayer(profile, layer) {
  return function thunk(dispatch, getState) {
    dispatch({
      type: "NOTIFICATION_SELECT_LAYER",
      layer,
    });
  };
}

export const clusterAppliancesFetcher = dataFetcher({
  selectors: [
    "cluster-details",
    "appliances",
    (state) => state.location.params.id,
    getClusterCloudType,
  ],
  async fetchData([_, __, uid, cloudType]) {
    const cluster = getCluster(store.getState());
    const edgeHostUid = cluster.spec?.cloudConfig?.spec?.edgeHostRef?.uid;
    const isHybrid = cluster.spec?.clusterConfig?.hybridClusterConfig?.enable;
    // for vsphere BM??
    if (edgeHostUid) {
      return api.get(`v1/edgehosts/${edgeHostUid}`).then((res) => ({
        items: parseAppliances([res] || []),
      }));
    }

    if (isHybrid) {
      let hosts = [];
      const pools = cluster.spec.cloudConfig.spec.hybridMachinePools;
      const edgeHostFetchers = pools.map((pool) => {
        return api
          .get(
            `v1/spectroclusters/${pool.cloudConfig.hybridClusterRef.uid}/${pool.poolCloudType}/edgeHosts`
          )
          .then((res) => {
            hosts = hosts.concat(parseAppliances(res?.items || []));
          });
      });

      await Promise.all(edgeHostFetchers);
      return {
        items: hosts,
      };
    }

    if (!["edge", "edge-native", "libvirt"].includes(cloudType)) {
      return {
        items: [],
      };
    }

    return api
      .get(`v1/spectroclusters/${uid}/${cloudType}/edgeHosts`)
      .then((res) => ({
        items: parseAppliances(res?.items || []),
      }));
  },
});

export const appliancesListActions = new ListActions({
  dataFetcher: clusterAppliancesFetcher,
  schema: [EdgeMachineSchema],
});

export const installProfileArtifactsAsyncAction = new AsyncAction({
  promise: async () => {
    const cluster = getCluster(store.getState());
    const promise = api.post(
      `v1/spectroclusters/${cluster.metadata.uid}/status/spcApply`
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t("The installation will begin shortly"),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: error?.message,
      });
    }
  },
});

function fetchAttachedProfileByUid(uid) {
  return function thunk(dispatch) {
    const promise = api.get(`v1/clusterprofiles/${uid}`);

    dispatch({
      type: "FETCH_ATTACHED_PROFILE",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export const kubectlModal = new ModalService();

export const kubectlRedirectURIAction = new AsyncAction({
  promise: async () => {
    const cluster = getCluster(store.getState());
    const projectUid = getCurrentProjectUidFromUrl(store.getState());

    return store.dispatch(
      kubectlRedirectUriFetcher.fetch({
        clusterUid: cluster.metadata.uid,
        projectUid,
      })
    );
  },
});

export function getKubectlRedirectURI() {
  return async function thunk(dispatch) {
    const response = await kubectlRedirectURIAction.trigger();

    if (response) {
      kubectlModal.open();
    }
  };
}

export function closeKubectlModal() {
  kubectlModal.close();
}

export function onReviewRevision(revisionUid) {
  return async function thunk(dispatch, getState) {
    const cluster = getRawCluster(getState());
    await dispatch(clusterRevisionFetcher.key(revisionUid).fetch());
    dispatch(revisionDiffModule.actions.openDiffSummaryModal({ revisionUid }));

    // NOTE: this is a temporary solution to recreate the snapshot
    // in order to get the unknown manifests after opening the diff summary modal
    await Promise.resolve();
    dispatch(revisionDiffModule.actions.openDiffSummaryModal({ revisionUid }));
    const unknownManifests =
      revisionDiffModule.selectors.getManifestsUnknownChanges(getState());
    const fetchUnknownManifestData = Promise.allSettled(
      unknownManifests.map((manifestChange) => {
        const currentManifest = manifestChange.current.manifest;
        const currentValueFetcher = api
          .get(
            `v1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${currentManifest.uid}`
          )
          .then((res) => {
            profileModule.actions.dispatch({
              type: "PROFILE_STACK_VALUE_CHANGE",
              values: res.spec.published.content,
              layer: currentManifest.guid,
            });
          });

        const targetManifest = manifestChange.target.manifest;
        const targetUid = targetManifest.uid.replace(`${revisionUid}-`, "");
        const targetValueFetcher = api
          .get(
            `v1/spectroclusters/${cluster.metadata.uid}/revisions/${revisionUid}/pack/manifests/${targetUid}`
          )
          .then((res) => {
            profileModule.actions.dispatch({
              type: "PROFILE_STACK_VALUE_CHANGE",
              values: res.spec.published.content,
              layer: targetManifest.guid,
            });
          });
        return Promise.allSettled([currentValueFetcher, targetValueFetcher]);
      })
    );

    // NOTE: this is a temporary solution to recreate the snapshot
    // for the differences after fetching the unknown manifests
    // this is needed to refresh the view of the diff summary modal
    await fetchUnknownManifestData;
    dispatch(revisionDiffModule.actions.openDiffSummaryModal({ revisionUid }));
  };
}

function onReviewClusterProfileChanges() {
  return async function thunk(dispatch) {
    await dispatch(
      clusterRepaveFetcher.fetch({ profiles: profileModule.payload })
    );
    dispatch(profileUpdatesDiffModule.actions.openDiffSummaryModal());
  };
}
